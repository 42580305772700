<template>
  <!-- eslint-disable vue/no-v-html -->
  <div
    v-if="displayForm"
    class="event-change-vue-container"
  >
    <span
      v-if="!submitted"
      v-html="description"
    />
    <div
      v-if="refundConfirmation"
      class="refund-amount"
    >
      <p v-if="refundAmount && contactSite != 'hofexperiences.com'">
        <b>{{ $t('cancellation_form.refundAmount') }}</b> {{ refundAmount }}
      </p>
      <p
        v-if="contactSite != 'hofexperiences.com'"
      >
        <b>{{ $t('cancellation_form.creditAmount') }}</b> {{ creditAmount }}
      </p>
    </div>
    <div
      v-if="submitted"
      class="change-request-success"
    >
      <p class="normal-text">
        <b>{{ $t('cancellation_form.selected') }}</b> {{ preference[0] }}
      </p>
      <br><br>
      <p>{{ $t('cancellation_form.success') }}</p>
      <br>
      <p>{{ $t('cancellation_form.gracias') }}</p>
    </div>
    <form
      v-else
      class="change-request-form"
      @submit.prevent="submitRefundConfirmation()"
    >
      <p class="change-request-steps">
        <b>{{ $t('cancellation_form.step1') }}</b> {{ $t('cancellation_form.step1Description') }}
      </p>
      <div class="form-group">
        <select
          v-model="preference"
          class="form-control"
          name="change_preference"
        >
          <option :value="null">
            {{ $t('cancellation_form.optionSelect') }}
          </option>
          <option
            v-for="option in preferences"
            :key="option[0]"
            :value="option"
          >
            {{ option[0] }}
          </option>
        </select>
      </div>
      <p class="change-request-steps">
        <b>{{ $t('cancellation_form.step2') }}</b> {{ $t('cancellation_form.step2Description') }}
      </p>
      <div class="terms-and-conditions">
        <p>
          <span
            class="terms-and-conditions-link"
            :class="disableDocusignButton || !preference ? 'btn-disabled' : ''"
            :disabled="disableDocusignButton"
            @click="submitPreferenceAndSign()"
          >
            {{ $t('cancellation_form.clickHere') }}
          </span>
          {{ $t('cancellation_form.clickHereDescription') }}
        </p>
        <p class="small">
          {{ $t('cancellation_form.docusignFinePrint') }}
        </p>
      </div>
      <div
        v-if="disableDocusignButton"
        class="processing-docusign"
      >
        <div class="loading-icon-container">
          <div class="loading-icon" />
        </div>
      </div>
      <div
        v-if="loadingDocusignStatus"
        class="docusign-status-loader"
      />
      <button
        v-else
        type="submit"
        class="btn btn-secondary"
        :class="completedDocusign && matchingPreferences ? '' : 'btn-disabled'"
      >
        {{ $t('cancellation_form.submit') }}
      </button>
    </form>
  </div>
</template>

<script>
import FetchCall from '../../../shared/local_fetch_call'
const months = [
  'January', 'February', 'March',
  'April', 'May', 'June',
  'July', 'August', 'September',
  'October', 'November', 'December'
]

export default {
  data() {
    return {
      confirmationId: null,
      billingName: '',
      opportunityId: null,
      contactSite: '',
      displayForm: false,
      refundConfirmation: false,
      refundAmount: null,
      creditAmount: null,
      submitted: false,
      preferences: [],
      description: null,
      preference: null,
      dbPreference: null,
      comments: '',
      day: null,
      month: null,
      disableDocusignButton: false,
      completedDocusign: false,
      loadingDocusignStatus: false
    }
  },

  computed: {
    matchingPreferences() {
      return this.preference[0] === this.dbPreference
    }
  },

  created() {
    this.hideChangeRequest()
    let elem = document.getElementById('opportunityCancellation')
    if(elem) {
      this.opportunityId = elem.dataset.opportunityid
      this.refundAmount = elem.dataset.refundamount
      this.creditAmount = elem.dataset.creditamount
      this.refundConfirmation = true
    }
    if (this.opportunityId) {
      this.fetchRefundConfirmationData()
    }
  },

  methods: {
    async fetchRefundConfirmationData() {
      let res = await FetchCall.fetchWithoutLocale(
        `/oa/opportunities/${this.opportunityId}/refund_confirmations.json`,
        'GET',
        null
      )
      if (!res.data) return

      this.confirmationId = res.confirmation_id
      this.contactSite = res.data.opportunity.contactSite
      const opportunity = res.data.opportunity
      const event = opportunity.event
      this.displayForm = event && event.eventChangeDisplayQng && this.creditAmount

      if (this.displayForm) {
        this.billingName = opportunity.billingName

        this.getDocusignStatus()

        this.submitted = opportunity.refundResponse ? true : false
        this.description = this.formDescription(event)
        this.createRefundPreferences(
          event.refundConfirmationPreference,
          opportunity.pricebook
        )

        this.dbPreference = res.confirmation_preference
        this.preference = res.confirmation_preference ?
          this.assignAddendum(res.confirmation_preference, opportunity.pricebook) :
          null

        this.todaysDate()

        if (this.submitted) {
          this.preference = this.assignAddendum(res.confirmation_preference, opportunity.pricebook)
        }

        if (opportunity.refundResponse) {
          this.closeHeader()
        }

        this.showChangeRequest()
      }
    },
    todaysDate() {
      let months = ['January', 'February', 'March',
        'April', 'May', 'June', 'July', 'August',
        'September', 'October', 'November', 'December']
      let date = new Date()
      this.day = date.getDate()
      this.month = months[date.getMonth()]
    },
    async submitPreferenceAndSign() {
      if (this.disableDocusignButton || !this.preference) return
      this.disableDocusignButton = true
      this.completedDocusign = false
      await this.updateRefundPreference()
      await this.generateDocusign()
      this.disableDocusignButton = false
    },
    async updateRefundPreference() {
      const formData = {
        confirmation_preference: this.preference[0]
      }

      await FetchCall.fetchWithoutLocale(
        `/oa/opportunities/${this.opportunityId}/refund_confirmations`,
        'PUT',
        JSON.stringify(formData)
      )
    },
    async generateDocusign() {
      const formData = {
        refund_confirmation_id: this.confirmationId,
        docusign_content: this.preference[1]
      }

      let res = await FetchCall.fetchWithoutLocale(
        '/api/customer_portal_signatures',
        'POST',
        JSON.stringify(formData)
      )

      if (res.docusign_url == undefined) {
        this.disableDocusignButton = false
        return
      }

      this.completedDocusign = false
      window.location.href = res.docusign_url
    },
    async submitRefundConfirmation() {
      if (!this.completedDocusign || !this.matchingPreferences) return

      await FetchCall.fetchWithoutLocale(
        `/oa/opportunities/${this.opportunityId}/sf_submit_refund_confirmations`,
        'POST',
        null
      )

      this.submitted = true
    },
    async getDocusignStatus() {
      this.loadingDocusignStatus = true
      let res = await FetchCall.fetchWithoutLocale(
        `/api/customer_portal_signatures?refund_confirmation_id=${this.confirmationId}`,
        'GET',
        null
      )
      this.loadingDocusignStatus = false
      if (!res) return
      this.completedDocusign = res.status === 'completed' ? true : false
    },
    createRefundPreferences(refundPreferences, pricebook) {
      let refundPrefs = refundPreferences.split(',').map(x => x.trim())

      refundPrefs.forEach(pref => {
        this.preferences.push(this.assignAddendum(pref, pricebook))
      })
    },
    formDescription(event) {
      let defDescription = event.refundConfirmationDescription
      switch (window.locale) {
      case 'fr':
        return event.refundConfirmationDescriptionFrench ?
          event.refundConfirmationDescriptionFrench : defDescription
      case 'de':
        return event.refundConfirmationDescriptionGerman ?
          event.refundConfirmationDescriptionGerman : defDescription
      case 'es-MX':
        return event.refundConfirmationDescriptionSpanish ?
          event.refundConfirmationDescriptionSpanish : defDescription
      default:
        return defDescription
      }
    },
    assignAddendum(preference, pricebook) {
      switch (preference) {
      case 'Apply Credit to New Date in 2020':
        return [
          preference,
          this.updateDynamicInfo(pricebook.addendum2020, true)
        ]

      case 'Apply Credit* to the 2021 Event':
      case 'Accept Transfer Amount*':
        return [
          preference,
          this.updateDynamicInfo(pricebook.addendum2021, true)
        ]

      default:
        return [
          preference,
          this.updateDynamicInfo(pricebook.refundAddendum)
        ]
      }
    },
    updateDynamicInfo(addendum, isCredit = false) {
      let newAddendum = addendum
      newAddendum = isCredit ?
        newAddendum.replace('XXXX.XX', `$${this.creditAmount}`) :
        newAddendum.replace('XXXX.XX', `$${this.refundAmount}`)

      newAddendum = newAddendum.replace(' XXX ', ` ${this.billingName} `)

      newAddendum = newAddendum.replace(/\\d1\\/g, this.addendumDate())
      return newAddendum
    },
    addendumDate() {
      const date = new Date()
      let day = date.getDate()
      let month = date.getMonth()
      let year = date.getFullYear()

      return `${day} day of ${months[month]}, ${year} `
    },
    hideChangeRequest() {
      document.getElementsByClassName('opportunity-change-request')[0].style.display = 'none'
    },
    showChangeRequest() {
      document.getElementsByClassName('opportunity-change-request')[0].style.display = 'flex'
    },
    closeHeader() {
      document
        .getElementsByClassName('opportunity-details-header change-request-color')[0]
        .click()
    }
  }
}
</script>
