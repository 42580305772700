<template>
  <div
    v-if="roomingLists.length > 0"
    class="opportunity-hotel-details opportunity-details-block"
  >
    <div
      :class="[expandedClass, highlightColor, 'opportunity-details-header']"
      @click="expanded = !expanded"
    >
      <p class="opportunity-details-header-title">
        {{ headerText }}
      </p>
      <span class="opportunity-details-expand-icon" />
    </div>

    <div :class="[expandedClass, 'opportunity-details-content']">
      <div class="disclaimer-info">
        <p v-if="incompleteRooms">
          {{ $t('roomingLists.finalize') }}
        </p>
        <!-- eslint-disable vue/no-v-html -->
        <p
          v-if="incompleteRooms"
          class="important"
          v-html="$t('roomingLists.deadline', { date: date })"
        />
        <!--eslint-enable-->
      </div>

      <RoomList
        v-for="(room, index) in roomingLists"
        :key="room.id"
        :num="index + 1"
        :opportunityid="opportunityId"
        :room="room"
        class="rooming-list-content"
        @complete-room="completeRoom"
      />
    </div>
  </div>
</template>

<script>
/* global locale */
import FetchCall from '../../../shared/local_fetch_call'
import RoomList from './room_list'

export default {
  components: {
    RoomList
  },

  data() {
    return {
      expanded: false,
      eventDate: null,
      opportunityId: null,
      roomingLists: [],
      updateableRoomingLists: []
    }
  },

  computed: {
    date() {
      return new Date(this.eventDate).toLocaleDateString(locale,
        { month: 'long', day: 'numeric', year: 'numeric', timeZone: 'UTC'})
    },

    expandedClass() {
      return (this.expanded == true) ? 'opened' : 'closed'
    },

    headerText() {
      if (this.incompleteRooms) {
        return this.$t('roomingLists.requiredDetails')
      } else {
        return this.$t('roomingLists.details')
      }
    },

    highlightColor() {
      if (this.incompleteRooms) {
        return 'required-hotel-details'
      } else {
        return ''
      }
    },

    incompleteRooms() {
      return this.updateableRoomingLists.length > 0
    }
  },

  created() {
    let elem = document.getElementById('opportunityRoomingList')
    if(elem) {
      this.opportunityId = elem.dataset.opportunityid
      this.eventDate = elem.dataset.eventdate
    }
    if (this.opportunityId) { this.fetchRoomingLists() }
  },

  methods: {
    completeRoom(id) {
      this.updateableRoomingLists = this.updateableRoomingLists.filter(
        room => room.id != id
      )
    },

    fetchRoomingLists() {
      return FetchCall.fetchWithLocale(
        `/oa/opportunities/${this.opportunityId}/rooming_lists.json`, 'GET', null
      ).then(response => {
        if (response.rooming_lists) {
          this.roomingLists = response.rooming_lists
          this.updateableRoomingLists = response.rooming_lists.filter(
            room => room.guest_name == null && room.quantity < 1
          )

          if (this.incompleteRooms) { this.expanded = true }
        }
      })
    },
  }
}
</script>
