var pkg_group = ''

$(document)
  .on('turbo:load', () => {
    let urlParams = new URLSearchParams(window.location.search)
    pkg_group = urlParams.get('package-group')

    if (pkg_group) {
      filterPackages()
      selectFilter()
    }
  })

function categories(pkg) {
  let categories = $(pkg).attr('data-category-names').split(',')
  let illegalChars = ['[', ']', '/', '"']

  return categories.map(category => {
    category = category.trim().split('')
    category = category.filter(char => !illegalChars.includes(char))
    return category.join('')
  })
}

function showHidePackage(pkg, hidden_pkgs = 0) {
  if (categories(pkg).includes(pkg_group)) {
    $(pkg).children('group-package').addClass('hidden')
    hidden_pkgs++
  } 

  return hidden_pkgs
}
function selectFilter() {
  $('.secondary-filter-wrapper .dropdown-menu li').each((i, elem) => {
    if (elem.dataset['filter'] == pkg_group) $(elem).find('.switch input').click()
  })
}

function filterPackages() {
  //seating chart pages
  if(document.getElementsByClassName('group-packages')[0]) {
    for(let group of $('.group-packages')) {
      let group_id = group.dataset.groupId
      let pkgs = '.group-' + group_id + ' .seatingChartPackage'
      let hidden_pkgs = 0

      $(pkgs).each(function () {
        if ($(this).attr('data-category-names')) {
          hidden_pkgs = showHidePackage($(this), hidden_pkgs)
        } else {
          $(this).addClass('hidden')
          hidden_pkgs++
        }
      })

      if($(pkgs).length == hidden_pkgs) {
        $('.group-' + group_id + ' .ticket-table-head').addClass('hidden')
      }
    }
  } else if (document.getElementsByClassName('flex-table')[0]) {
    $('.flex-table.row').each(function () {
      if ($(this).attr('data-category-names')) {
        showHidePackage($(this))
      } else {
        $(this).addClass('hidden')
      }
    })
  } else {
    $('.table tr').each(function () {
      if ($(this).attr('data-category-names')) {
        showHidePackage($(this))
      } else {
        $(this).addClass('hidden')
      }
    })
  }
}
