let myDefaultWhiteList = $.fn.tooltip.Constructor.DEFAULTS.whiteList
myDefaultWhiteList.g = []
var PreviousElement
window.tooltipReference = null
window.hoverable = true

const isGPE = () => window.brand_name == 'Grand Prix Events'
const isDerby = () => window.brand_name == 'Derby Experiences'
const isJsEntrypointNotDerbyV3 = () => window.js_entrypoint != 'derby-v3'

// let current = {x: 0, y: 0}
// let target = {x: 0, y: 0}
document.addEventListener('turbo:load', () => {
  var visibleSVGDiv = $('.seating-chart-image.zoomed:visible')[0]
  if (visibleSVGDiv){
    var elem = visibleSVGDiv.querySelector(`#${visibleSVGDiv.dataset.position}`)
    setTooltip(elem)
  } else {
    Array.from(document.querySelectorAll('[data-toggle=svg-tooltip]')).forEach( (elem) => {
      elem.addEventListener('mouseover', () => {
        setTooltip(elem)
      }, 0)
      elem.addEventListener('click', () => {
        if(isGPE() || (isDerby() && isJsEntrypointNotDerbyV3())) {
          var targetDiv = $($(`.svg-${elem.id}`)[0])
          if (targetDiv && targetDiv.offset()){
            $([document.documentElement, document.body]).animate({
              scrollTop: (
                targetDiv.offset().top - 70 - $('.ticket-table-head').height()
              )}, 1000)
          }
        }
      })
    })
    Array.from(document.querySelectorAll('[data-toggle=svg-section-tooltip]')).forEach( (elem) => {
      elem.addEventListener('mouseover', () => {
        let target = document.getElementById(elem.dataset.sectionId)
        setTooltip(target)
      }, 0)
    })
  }
})

const hideTooltip = () => {
  let tooltips = document.querySelectorAll('[id^=svg-tooltip]')
  if (tooltips) {
    for (let tooltip of tooltips) {
      tooltip.style.display = 'none'
    }
  }
}

const showTooltip = () => {
  let tooltips = document.querySelectorAll('[id^=svg-tooltip]')
  if (tooltips) {
    for (let tooltip of tooltips) {
      tooltip.style.display = 'block'
    }
  }
}

document.addEventListener('turbo:load', () => {
  Array.from(document.querySelectorAll('#scrollbar, [data-toggle=svg-tooltip]')).forEach( (elem) => {
    elem.addEventListener('mouseover', () => {
      showTooltip()
    })

    elem.addEventListener('mouseout', () => {
      hideTooltip()
    })
  })
})

export function resetTooltip(tooltip) {
  var visibleSVGDiv = $('.seating-chart-image.zoomed:visible, #svg-seatingchart, .rightsidemap, .svg-img:visible, .leftsidemap')[0]
  var selectorString = `#${tooltip.dataset.current_id}`
  if (tooltip.dataset.extra_data) selectorString = `path[d='${tooltip.dataset.extra_data}']`
  let elem = visibleSVGDiv.querySelector(selectorString)
  setTooltip(elem)
}



export function setTooltip(elem, position = null) {
  if (!elem) return
  let infoForm = document.getElementById('infoRequestFormSlider')
  if (infoForm && infoForm.classList.contains('display')) return
  var tooltip = null
  position == null ? tooltip = document.getElementById('svg-tooltip') : tooltip = document.getElementById('svg-tooltip' + position)

  if (tooltip) {
    tooltip.style.display = 'block'
  }
  let elemRectangle = elem.getBoundingClientRect()
  let bodyRectangle = $('.form-and-seating-chart, .controller_seating_charts.action_show, .rightsidemap, .leftsidemap')[0].getBoundingClientRect()
  let ttX = elemRectangle.left + elemRectangle.width/2 - bodyRectangle.left
  let ttY = elemRectangle.top + elemRectangle.height/2 - bodyRectangle.top

  if (tooltip == null) {
    tooltip = document.createElement('div')
    position == null ? tooltip.id = 'svg-tooltip' : tooltip.id = 'svg-tooltip' + position
    let inner = document.createElement('div')
    inner.className = 'inner'
    tooltip.appendChild(inner)
    $('.form-and-seating-chart, .controller_seating_charts.action_show, .rightsidemap, .leftsidemap')[0].appendChild(tooltip)
  }
  tooltip.style.transform = `translate3d(${ttX}px, ${ttY}px, 0)`
  tooltip.style.transition = 'transform .5s ease-in-out'
  tooltip.classList = `svg-tooltip ${elem.dataset.placement}`
  if(elem.id.length > 0) {
    tooltip.dataset.current_id = elem.id
  } else {
    tooltip.dataset.current_id = `${elem.parentElement.id} .${elem.classList.value.replace(' ', '.')}`
  }
  if (elem.getAttribute('d')) {
    tooltip.dataset.extra_data = elem.getAttribute('d')
  } else {
    delete tooltip.dataset.extra_data
  }
  let tooltipInner = tooltip.firstChild
  tooltipInner.innerText = elem.getAttribute('title') || elem.getAttribute('id')

  if(!PreviousElement||PreviousElement!=elem) {
    if(window.brand_name == 'Derby Experiences') {
      if(PreviousElement)
        PreviousElement.setAttribute('stroke-width',1)
      elem.style.stroke = '#6D6E71'

      elem.setAttribute('stroke-width',1)
      elem.style.stroke = '#6D6E71'
    } else {
      if(PreviousElement)
        PreviousElement.setAttribute('stroke-width',0)
      elem.setAttribute('stroke-width',3)
    }

    var x = elem.clientX
    var y = elem.clientY

    var scrollX = window.pageXOffset
    var scrollY = window.pageYOffset

    var divLeft=x+5+scrollX+'px'
    var divTop=y+5+scrollY+'px'

    tooltip.style.left=divLeft
    tooltip.style.top=divTop
    tooltip.style.visibility='visible'
    PreviousElement=elem
  }
}



$(document)
  .on('mouseover', '.group-package', function () {
    window.tooltipReference = this
    if(window.hoverable)
      tooltipSetter(this)
  })

export function tooltipSetter(tr) {
  if($(tr).find('.ticket-label').attr('data-position')) {
    var package_positions = $(tr).find('.ticket-label').attr('data-position').split(',')

    if(package_positions.length == 1) {
      var tooltips = $('[id^=svg-tooltip]')
      for (let tooltip of tooltips) {
        if(tooltip.id != 'svg-tooltip') {
          $(tooltip).remove()
        }
      }
      setTooltip(document.getElementById(package_positions))
    } else {
      setTooltip(document.getElementById(package_positions[0]))
      for (let i = 1; i < package_positions.length; i++) {
        setTooltip(document.getElementById(package_positions[i]), i)
      }
    }
  }
}
