<template>
  <div>
    <p
      v-if="chargeDate"
      class="details payment-term-details charge-date"
    >
      {{ $t('paymentTerms.charged_on', { date: chargeDate }) }}
    </p>
    <Refunds :paymenttermid="paymentTerm.id" />
  </div>
</template>


<script>
/* global locale */

import Refunds from '../refunds/list'

export default {
  components: {
    Refunds
  },

  props: {
    paymentTerm: {
      type: Object,
      default: () => ({
        charge_date: String,
        id: Number
      })
    }
  },

  computed: {
    chargeDate() {
      if (this.paymentTerm.charge_date) {
        return new Date(this.paymentTerm.charge_date).toLocaleDateString(locale,
          { month: 'long', day: 'numeric', year: 'numeric', timeZone: 'UTC'})
      } else {
        return null
      }
    },
  }
}
</script>
