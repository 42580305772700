$(document)
  .on('turbo:load', () =>
    //#add <sup> to registration marks
    $('body :not(script, iframe, sup)').contents()
      .filter(function() { return this.nodeType === 3 })
      .replaceWith(function() {
        return this.nodeValue.replace(/[™®©]/g, '<sup>$&</sup>')
      })
  )
document.addEventListener('turbo:load', () => {
  let button = document.getElementById('admin-logout')
  let form = document.getElementById('password_form')
  if(button) {
    form.style.pointerEvents = 'none'
  }
})
