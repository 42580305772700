<template>
  <section
    v-if="hotelReservations.length > 0"
    id="confirmationHotelReservations"
    class="confirmation-hotel-reservations"
  >
    <h3>{{ $t('roomingLists.confirmation_hotelDetails') }}</h3>
    <!-- eslint-disable vue/no-v-html -->
    <p v-html="$t('roomingLists.confirmation_deadline', { date: date })" />
    <!--eslint-enable-->
    <p>{{ $t('roomingLists.confirmation_deadlineLocation') }}</p>

    <div class="reservations">
      <HotelReservation
        v-for="reservation in hotelReservations"
        :key="reservation.id"
        :reservation="reservation"
      />
    </div>
  </section>
</template>

<script>
/* global locale */

import FetchCall from '../../../shared/local_fetch_call'
import HotelReservation from './hotelReservation'

export default {
  components: {
    HotelReservation
  },

  data() {
    return {
      eventDate: null,
      hotelReservations: []
    }
  },

  computed: {
    date() {
      return new Date(this.eventDate).toLocaleDateString(locale,
        { month: 'long', day: 'numeric', year: 'numeric', timeZone: 'UTC'})
    }
  },

  created() {
    this.fetchEvents()
    this.eventDate = this.$parent.$el.dataset.eventdate
  },

  methods: {
    fetchEvents() {
      FetchCall.fetchWithoutLocale(
        '/api/hotel_reservations.json', 'GET', null
      ).then(response => {
        if (response.hotel_reservations) {
          this.hotelReservations = response.hotel_reservations
        }
      })
    }
  }
}
</script>
