<template>
  <div class="opportunity-show-payment-form">
    <div class="close-button">
      <button
        class="btn btn-secondary"
        @click="$emit('close')"
      >
        {{ $t('navCart.close') }}
      </button>
    </div>

    <CreditCardForm
      v-if="card.id == null"
      :address="opportunity.street"
      :buttontext="buttonText"
      :city="opportunity.city"
      :name="opportunity.name"
      :phone="opportunity.phone"
      :state="opportunity.state"
      :zipcode="opportunity.zip"
      @card-added="addCard"
    />

    <div
      v-else
      :class="[updating ? 'loading-card' : '', 'stripe-card-form']"
    >
      <div class="loading-icon" />

      <h3>
        {{ $t('paymentTerms.select_terms') }}
      </h3>

      <div
        v-for="(term, i) in passedTerms"
        :key="term.id"
        class="payment-terms-change-card"
      >
        <input
          :id="term.id"
          v-model="updatePaymentTermIds"
          :value="term.id"
          type="checkbox"
        >

        <label :for="term.id">
          {{ $t('paymentTerms.payment_number', { number: i + 1 }) }}
          {{ term.amount_decorated }}
        </label>
      </div>

      <button
        class="continue-button btn btn-primary"
        :disabled="updating"
        @click="updatePaymentTerms"
      >
        {{ $t('paymentTerms.addCreditCard') }}
      </button>
    </div>
  </div>
</template>

<script>
import CreditCardForm from '../../../shared/stripe_form/add_card_without_charging_form.vue'
import FetchCall from '../../../shared/local_fetch_call'

export default {
  components: {
    CreditCardForm
  },

  props: {
    opportunity: {
      default: () => {},
      type: Object
    },
    paymentTerms: {
      default: () => [],
      type: Array
    }
  },

  data() {
    return {
      card: {},
      updatePaymentTermIds: [],
      updating: false
    }
  },

  computed: {
    buttonText() {
      return this.$t('paymentTerms.addCreditCard')
    },

    passedTerms() {
      return this.paymentTerms.filter(term => term.status != 'passed')
    }
  },

  methods: {
    addCard(data) {
      if (data.card) { this.card = data.card }
    },

    updatePaymentTerms() {
      if (this.updating) { return }
      this.updating = true

      let ids = this.updatePaymentTermIds
      let promiseArray = []

      ids.forEach(id => {
        let promise = new Promise((resolve, reject) => {
          this.updatePaymentTerm(id).
            then(result => {
              resolve(result)
            }).catch(err => {
              reject(err)
            })
        })

        promiseArray.push(promise)
      })

      Promise.all(promiseArray).then(() => {
        this.updating = false
        this.$emit('refresh')
      })
        .catch(error => {
          console.log('Error updating payment terms:', error)
          this.updating = false
        })
    },

    updatePaymentTerm(id) {
      let body = JSON.stringify({ payment_term: { source_id: this.card.id } })

      return FetchCall.fetchWithLocale(`/oa/payment_terms/${id}.json`, 'PUT', body).
        then(response => { return response })
    },
  }
}
</script>
