/* global locale */
import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'
import { messages } from 'default/translated_text'
import hotelCarousel from './hotel_carousel'

function renderVue() {
  if (!document.getElementById('admin-hotel-image-container')) return
  const i18n = createI18n({ locale, messages, fallbackLocale: 'en' })
  return createApp({
    i18n,
    render: h => h(hotelCarousel)
  }).$mount('#admin-hotel-image-container')
}

document.addEventListener('turbo:load', () => {
  renderVue()
})
