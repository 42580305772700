import './stripe_orders'
import './opportunities'

import HotelReservations from './confirmation/hotelReservations'
import VueLoader from '../vue-loader'

document.addEventListener('turbo:load', () => {
  VueLoader.loadVueComponent(HotelReservations,
    document.getElementById('confirmationHotelReservations'))
})
