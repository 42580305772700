<template>
  <div>
    <p class="details payment-term-details charge-date">
      {{ $t('paymentTerms.canceled') }}
    </p>
    <Refunds :paymenttermid="paymentTerm.id" />
  </div>
</template>


<script>
import Refunds from '../refunds/list'

export default {
  components: {
    Refunds
  },

  props: {
    paymentTerm: {
      default: () => ({ id: Number }),
      type: Object
    }
  },
}
</script>
