function smooth_scroll() {
  get_links().click(function(event) {
    if ( only_on_page_links(event) ) {
      var target_element = get_element_to_scroll_to(event)
      if (target_element.length) {
        event.preventDefault()
        $('html, body').animate({
          scrollTop: target_element.offset().top
        }, 1000, function() {
          // Callback after animation to change focus for accessibility purposes
          var $target_element = $(target_element)
          $target_element.focus()
        })
      }
    }
  })
}

function get_links() {
  return $('a[href*="#"]')
    .not('.no-scroll')
    .not('[href="#"]')
    .not('[href="#0"]')
    .not('.arrow > a')
    .not('.panel-title > a')
}

function only_on_page_links(event) {
  let eventPathName = event.target.pathname || event.currentTarget.pathname || ''
  let eventHostname = event.target.hostname || event.currentTarget.hostname || ''

  return location.pathname.replace(/^\//, '') == eventPathName.replace(/^\//, '')
  &&
  location.hostname == eventHostname
}

function get_element_to_scroll_to(event) {
  let target = $(event.target.hash || event.currentTarget.hash || '')

  target = target.length ? target : $('[name=' + event.target.hash.slice(1) + ']')
  return target
}

document.addEventListener('turbo:load', function(){
  smooth_scroll()
}, true)
