function phoneNumber() {
  var location = document.getElementById('country-code').dataset.code
  var phoneNumber = window.phone_numbers[location] || window.phone_numbers['default']
  return phoneNumber
}

function checkForErrors(elem) {
  if (elem.checkValidity()){
    elem.classList.remove('has-error')

    if (elem.nextElementSibling.tagName == 'LABEL') {
      elem.nextElementSibling.remove()
    }

  } else {
    elem.classList.add('has-error')

    if (elem.nextElementSibling.tagName != 'LABEL') {
      const newlabel = document.createElement('Label')
      newlabel.classList.add('required-field-label')
      newlabel.innerHTML = 'This field is required'
      elem.insertAdjacentElement('afterend', newlabel)
    }
  }
}

$(document)

  .on('turbo:load', function(){
    if(document.getElementById('qe_new_info_request')) {
      document.getElementById('qe_info_request_submit').addEventListener('click', function(e) {
        if (!document.getElementById('qe_new_info_request').checkValidity()){
          document.getElementById('qe_new_info_request').querySelectorAll('[required]').forEach( function(elem) {
            checkForErrors(elem)
          })
          return e.preventDefault()
        }
        setTimeout( function() {
          document.getElementById('qe_new_info_request').reset()
          $('#qe_new_info_request').hide()
          const newDiv = document.createElement('div')
          newDiv.setAttribute('id', 'qe_info_request_confirmation')
          const confirmationContent = document.createTextNode(`Your inquiry has been forwarded to a QuintEvents representative who will be in touch soon! If you have immediate questions, please call ${phoneNumber()}`)
          const close = document.createTextNode('close')
          const btn = document.createElement('button')
          btn.appendChild(close)
          btn.setAttribute('id', 'qe_info_request_close_btn')

          btn.onclick = function(){
            $('#qe_info_request_confirmation').hide()
            $('#qe_new_info_request').show()
          }

          newDiv.appendChild(confirmationContent)
          newDiv.appendChild(btn)
          document.getElementsByClassName('qe-new-info-request-form-wrapper')[0].appendChild(newDiv)
        }, 800 )
      })
    }

    if(document.getElementById('qe_new_info_request')){
      document.getElementById('qe_new_info_request').querySelectorAll('[required]').forEach( function(elem) {
        elem.addEventListener('blur', () => {
          checkForErrors(elem)
        })
      })
    }
  })
