<template>
  <div
    v-if="shipmentCodes.length > 0"
    class="opportunity-shipment-code opportunity-details-block"
  >
    <div
      :class="[expandedClass, 'opportunity-details-header']"
      @click="expanded = !expanded"
    >
      <p class="opportunity-details-header-title">
        {{ $t('shipmentCodes.orderShipments') }}
      </p>

      <span class="opportunity-details-expand-icon" />
    </div>

    <div :class="[expandedClass, 'opportunity-details-content']">
      <div class="details">
        <p class="details tracking-details">
          {{ $t('shipmentCodes.instructions', {carrier: shipmentCodes[0].carrier }) }}
        </p>

        <div
          v-for="shipment in shipmentCodes"
          :key="shipment.tracking_number"
        >
          <p class="details tracking-numbers">
            <a
              class="details"
              :href="shipment.tracking_url"
              target="_blank"
            >
              {{ shipment.tracking_number }}
            </a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FetchCall from '../../../shared/local_fetch_call'

export default {
  data() {
    return {
      expanded: false,
      opportunityId: null,
      shipmentCodes: []
    }
  },

  computed: {
    expandedClass() {
      return (this.expanded == true) ? 'opened' : 'closed'
    }
  },

  created() {
    let elem = document.getElementById('opportunityShipmentCode')
    if (elem) {
      this.opportunityId = elem.dataset.opportunityId
    }
    this.fetchShipmentCodes()
  },

  methods: {
    fetchShipmentCodes() {
      return FetchCall.fetchWithLocale(
        `/oa/opportunities/${this.opportunityId}/shipment_codes.json`, 'GET', null
      ).then(response => {
        if (response.shipment_codes) { this.shipmentCodes = response.shipment_codes }
      })
    },
  }
}
</script>
