<template>
  <div>
    <p class="details payment-term-details due-date">
      {{ dueDateText }}
    </p>

    <div class="charge-buttons">
      <button
        class="btn btn-primary pay-now-button"
        @click="startCheckout"
      >
        {{ $t('paymentTerms.pay_now') }}
      </button>
    </div>
  </div>
</template>


<script>
/* global locale */

import FetchCall from '../../../shared/local_fetch_call'

export default {
  props: {
    paymentTerm: {
      default: () => {},
      type: Object
    },
  },

  data() {
    return {
      source: {}
    }
  },

  computed: {

    date() {
      if (this.paymentTerm.due_date) {
        return new Date(this.paymentTerm.due_date)
      } else {
        return new Date(this.paymentTerm.charge_date)
      }
    },

    dueDate() {
      return this.date.toLocaleDateString(locale,
        { month: 'long', day: 'numeric', year: 'numeric', timeZone: 'UTC'})
    },

    dueDateText() {
      if (this.source.last4 && this.date <= new Date) {
        return this.$t('paymentTerms.due_soon')
      } else if (this.source.last4) {
        return this.$t('paymentTerms.due_with_card',
          { date: this.dueDate, last4: this.source.last4 })
      } else {
        return this.$t('paymentTerms.due', { date: this.dueDate })
      }
    }
  },

  watch: {
    paymentTerm() {
      this.getSource()
    }
  },

  created() {
    this.getSource()
  },

  methods: {
    getSource() {
      if (this.paymentTerm.source_id) {
        FetchCall.fetchWithLocale(
          `/oa/payment_methods/${this.paymentTerm.source_id}.json`,
          'GET',
          null
        ).then(source => {
          if (source.id) { this.source = source }
        })
      }
    },

    startCheckout() {
      if(document.getElementById('admin-logout')) {
        return false
      } else {
        this.$emit('pay-now', this.paymentTerm)
      }
    }
  }
}
</script>
