/* global Stripe */

import { t } from 'default/translate_override.js'

let card, cardErr, cardExp, cardExpErr, cvc, cvcErr, displayError, elements, stripe

let style = {
  base: {
    fontSize: '14px',
    '::placeholder': {
      color: '#999999'
    }
  }
}

if (window.brand_name == 'Elite Supercar Tours') {
  style = {
    base: {
      fontSize: 'small',
      color: '#fff',
      '::placeholder': {
        color: '#fff'
      }
    }
  }
}

if (window.brand_name == 'Sportsnet') {
  style = {
    base: {
      lineHeight: '1.7',
      fontSize: '16px',
    }
  }
}

function createElement(stripeElement, errorDiv, id, placeholder) {
  let variable = elements.create(stripeElement, { style: style, placeholder: placeholder })
  variable.mount(id)

  variable.addEventListener('change', function(event) {
    if (event.error) {
      errorDiv.textContent = event.error.message
    } else {
      errorDiv.textContent = ''
      displayError.textContent = ''
    }
  })

  return variable
}

function createAmexCard(stripeElement, errorDiv, id, placeholder) {
  let variable = elements.create(stripeElement, { style: style, placeholder: placeholder })
  variable.mount(id)

  var acceptedCard = 'amex'

  variable.addEventListener('change', function(event) {
    if (event.error) {
      errorDiv.textContent = event.error.message
    } else if (acceptedCard !== event.brand) {
      // document.getElementById('submit-button').disabled = true;
      errorDiv.textContent = t('amexRequired')
      errorDiv.id = 'amex-error'
    } else {
      errorDiv.textContent = ''
      displayError.textContent = ''
    }
  })

  return variable
}

function mountElements(amexOnly) {
  displayError = document.getElementById('card-errors')
  cardErr = document.getElementById('cardErr')
  cardExpErr = document.getElementById('cardExpErr')
  cvcErr = document.getElementById('cvcErr')

  if (amexOnly) {
    card = createAmexCard('cardNumber', cardErr, '#card-number', t('creditCardNumber'))
  } else {
    card = createElement('cardNumber', cardErr, '#card-number', t('creditCardNumber'))
  }
  cvc = createElement('cardCvc', cvcErr, '#card-cvc', t('cVV'))
  cardExp = createElement('cardExpiry', cardExpErr, '#card-exp', t('expirationDate'))
}

export default {
  addErrorMessage(message) {
    displayError.textContent = message
  },

  cardIDToBeChargedImmediately(billingDetails) {
    return stripe.createPaymentMethod('card', card, {
      billing_details: billingDetails
    }).then(result => {
      if (result.error) {
        this.addErrorMessage(result.error.message)
        return 'error'
      } else {
        return result.paymentMethod.id
      }
    })
  },

  cardIDToBeChargedLater(clientSecret, billingDetails) {
    return stripe.handleCardSetup(
      clientSecret,
      card,
      { payment_method_data: { billing_details: billingDetails } }
    ).then(result => {
      if (result.error) {
        this.addErrorMessage(result.error.message)
        return 'error'
      } else {
        return result.setupIntent.payment_method
      }
    })
  },

  generateForm(amexOnly=false) {
    stripe = Stripe(window.public_token)
    elements = stripe.elements({ locale: window.locale })
    mountElements(amexOnly)
  },

  paymentIntentSCA(clientSecret) {
    return stripe.handleCardAction(clientSecret).then(result => {
      return result
    })
  },

  removeElements() {
    card.destroy()
    cvc.destroy()
    cardExp.destroy()
  }
}
