<template>
  <ImageCarousel 
    :images="images"
    :retail="false"
  />
</template>

<script>
import ImageCarousel from '../shared/add_to_cart_forms/h4h_rooms/hotel_images'

export default {
  components: {
    ImageCarousel
  },
  
  data() {
    return {
      images: []
    }
  },

  created() {
    let elements = document.getElementsByClassName('image')
    for (const element of elements) {
      this.images.push({ImagePath: element.src, ImageCaption: element.alt})
    }
  }
}
</script>