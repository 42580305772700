const delayAnimation = (elem, lg) => {
  $(elem).closest('.fade-in-delay-wrapper').children('.fade-in-delay').each(function(index) {
    let delay = lg ? 2 : index*2 + 2
    let time_delay = delay >= 10 ? delay*.1 + 's' : '.' + delay + 's'

    $(this).css({'animation-delay':  time_delay})
    $(this).removeClass('hide-elem')
    $(this).addClass('fadeIn')
  })
}

const showAnimation = (elem) => {
  if (elem.classList.contains('fade-in-delay')) {
    if($(elem).closest('.fade-in-delay-wrapper.fade-lg').length) {
      delayAnimation(elem, 1)
    } else {
      delayAnimation(elem, 0)
    }
  } else {
    elem.classList.remove('hide-elem')
    elem.classList.add('fadeIn')
  }
}

const fadeIn = (elem) => {
  let half_of_object = 0
  if ($(elem).closest('.fade-in-delay-wrapper.fade-lg').length) {
    half_of_object = $(elem).offset().top + $(elem).outerHeight()/5
  } else {
    half_of_object = $(elem).offset().top + $(elem).outerHeight()/3
  }

  let bottom_of_window = $(window).scrollTop() + $(window).height()

  if (bottom_of_window > half_of_object) {
    if(!elem.classList.contains('fadeInAnimate')) {
      showAnimation(elem)
      elem.classList.add('fadeInAnimate')
    }
  }

  if (bottom_of_window < half_of_object) {
    if(!elem.classList.contains('fadeInAnimate')) {
      elem.classList.remove('fadeIn')
      elem.classList.add('hide-elem')
    }
  }
}


document.addEventListener('turbo:load', () => {
  document.querySelectorAll('.fade-in').forEach( elem => {
    fadeIn(elem)
  })
})

window.onscroll = function() {
  document.querySelectorAll('.fade-in').forEach( elem => {
    fadeIn(elem)
  })
}
