import 'owl.carousel'
const breakPointSmall = 768
const breakPointMedium = 992

export function addOwlCarousels() {
  $('.owl-carousel-container').each(function() {
    let carousel = $(this).find('.owl-carousel')
    carousel.owlCarousel({
      margin: carousel.data('margin') || 50,
      loop: carousel.data('loop') || true,
      autoplaySpeed: 750,
      autoHeight: carousel.data('auto-height') || false,
      dots: carousel.data('dots') || false,
      nav: carousel.data('nav') || false,
      responsiveClass: true,
      rtl: $('main').css('direction') == 'rtl',
      responsive: {
        0: {
          items: 1
        },
        [breakPointSmall]: {
          items: carousel.data('items-small') || 1
        },
        [breakPointMedium]: {
          items: carousel.data('items-medium') || 1
        }
      }
    })
    // carousel.dispatchEvent('stop.owl')
    if (carousel.data('speed') > 0) {
      carousel.dispatchEvent('play.owl', carousel.data('speed'))
    }
    $(this).find('.carousel-control.left').click(() => carousel.dispatchEvent('prev.owl.carousel'))
    $(this).find('.carousel-control.right').click(() => carousel.dispatchEvent('next.owl.carousel'))

    if(carousel.data('auto-height')){
      setTimeout(function(){ carousel.dispatchEvent('refresh.owl.carousel') }, 500)
    }
  })
}

$(document)

  .on('turbo:load', function() {
    addOwlCarousels()
  })

  .on('turbo:before-cache', function() {
    if (!($('.owl-loaded').length > 0)) { return }
    $('.owl-loaded').each(function() { $(this).data('owl.carousel').destroy() })
  })

  .on('click', '*[data-toggle="lightbox"]', function(event) {
    event.preventDefault()
    $(this).ekkoLightbox({
      onContentLoaded() {
        const svgImage = $('.ekko-lightbox-container').find('img[src$=\'.svg\']')
        if (svgImage[0]) {
          $('.ekko-lightbox .modal-dialog')
            .css('max-height','50%')
            .css('max-width', '50%')
        }
      }})
  })
