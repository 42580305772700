<template>
  <!-- eslint-disable vue/no-v-html -->
  <span
    class="notranslate currency-to-update"
    :data-usd="totalUSD"
    v-html="total"
  />
  <!--eslint-enable-->
</template>

<script>
import CurrencyApi from './currency-api.js'

export default {
  props: {
    amount: {
      default: null,
      type: Number
    },
    currency: {
      default: 'USD',
      type: String
    },
    usd: {
      default: null,
      type: String
    }
  },
  data: function() {
    return {
      total: '',
      locale: 'en-US'
    }
  },

  computed: {
    totalUSD() {
      return this.usd
    }
  },

  watch: {
    amount() {
      this.setCurrency()
    },
    currency() {
      this.setCurrency()
    }
  },
  created() {
    this.locale = navigator.language || 'en-US'
    this.setCurrency()
  },
  methods: {
    setCurrency() {
      var total = Number(this.amount).toFixed(2)
      if (this.currency == 'USD') {
        this.total = '$' + total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      } else {

        this.total = CurrencyApi.formatAmount(total, this.currency)
      }
    }
  }
}
</script>

<style scoped>
</style>
