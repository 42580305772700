<template>
  <div class="hotel-reservation-form">
    <p><strong>{{ reservation.hotel_name }}</strong></p>

    <p>{{ reservation.room_type }}</p>

    <div class="hotel-reservation-info-group">
      <p class="form-label">
        <strong>{{ $t('roomingLists.resName') }}</strong>
      </p>

      <div
        v-if="completedGuestName == null"
        class="hotel-form-field"
      >
        <input
          v-model="guestName"
          class="form-control"
          name="room_guest_name"
          type="text"
          @keyup="resetMessage"
        >
      </div>

      <div
        v-else
        class="hotel-completed-content"
      >
        <p>{{ guestName }}</p>
      </div>
    </div>

    <div class="hotel-reservation-info-group">
      <p class="form-label">
        <strong>{{ $t('roomingLists.numAdults') }}</strong>
      </p>

      <div
        v-if="completedNumberOfAdults > 0"
        class="hotel-completed-content"
      >
        <p>{{ numberOfAdults }}</p>
      </div>

      <div
        v-else
        class="hotel-form-field"
      >
        <select
          id="max_room_capacity"
          v-model="numberOfAdults"
          class="form-control"
        >
          <option
            v-for="n in maxRoomCapacity"
            :key="n"
            :value="n"
            @change="resetMessage"
          >
            {{ n }}
          </option>
        </select>
      </div>
    </div>

    <button
      v-if="incomplete"
      class="btn btn-primary update-rooming-list-button"
      @click="updateRoom"
      @keyup.enter="updateRoom"
    >
      {{ $t('roomingLists.save') }}
    </button>

    <p
      v-if="message != null"
      @click="resetMessage"
    >
      {{ message }}
    </p>
  </div>
</template>

<script>
import FetchCall from '../../../shared/local_fetch_call'

export default {
  props: {
    reservation: {
      default: () => {},
      required: true,
      type: Object
    }
  },

  data() {
    return {
      completedGuestName: null,
      completedNumberOfAdults: 0,
      guestName: null,
      message: null,
      numberOfAdults: 2,
      processing: false
    }
  },

  computed: {
    incomplete() {
      return this.completedGuestName == null || this.completedNumberOfAdults == 0
    },

    maxRoomCapacity() {
      if (this.reservation.max_room_capacity > 0) {
        return this.reservation.max_room_capacity
      } else {
        return 2
      }
    }
  },

  created() {
    this.updateName(this.reservation)
    this.updateQuantity(this.reservation)
  },

  methods: {
    updateRoom() {
      if (this.processing) { return }
      this.processing = true

      let body = JSON.stringify({
        hotel_reservation: { name: this.guestName, number_of_adults: this.numberOfAdults }
      })

      FetchCall.fetchWithoutLocale(
        `/api/hotel_reservations/${this.reservation.id}`,
        'PUT',
        body
      ).then(response => {
        if (response.id) {
          this.updateName(response)
          this.updateQuantity(response)
        } else {
          this.message = this.$t('roomingLists.updateFailed')
        }

        this.processing = false
      })
    },

    resetMessage() {
      this.message = null
    },

    updateName(res) {
      this.completedGuestName = res.name
      this.guestName = res.name
    },

    updateQuantity(res) {
      if (res.number_of_adults > 0) {
        this.completedNumberOfAdults = res.number_of_adults
        this.numberOfAdults = res.number_of_adults
      }
    }
  }
}
</script>
