/* global locale languages */

const host = () => window.location.host

const langStr = (lang) => lang.startsWith('en') ? '' : `/${lang}`

$(document)
  .on('turbo:load', function() {
    translatePaths()
    $('.language-menu .left-menu, .language-menu .right-menu').empty()
    let langs_list = []
    let href= ''
    if (Object.keys(languages).length) {
      const leftMenu = $('.left-menu')
      const rightMenu = $('.right-menu')
      for (let i in languages) {
        let lang = languages[i]
        langs_list.push(lang.locale)
        if(lang.locale == 'en-GB'){
          href = `//${host()}${relativePath('/en', window.location)}`
        }else{
          href = `//${host()}${relativePath(`/${lang.locale}`, window.location)}`
        }

        if(window.brand_name == 'NBA Experiences') {
          if (parseInt(i) <= languages.length/2) {
            leftMenu.append(`<li class="menu-item navbar-item nav-item"> <a class="nav-link language ${lang.html_class}" onclick="window.location.href='${href}'"> <div class="navbar-item">${lang.display_name}</div></a> </li>`)
          }else {
            rightMenu.append(`<li class="menu-item navbar-item nav-item"> <a class="nav-link language ${lang.html_class}" onclick="window.location.href='${href}'"><div class="navbar-item">${lang.display_name}</div></a> </li>`)
          }
        } else {
          if (parseInt(i) <= languages.length/2) {
            leftMenu.append(`<li> <a class="language ${lang.html_class}" onclick="window.location.href='${href}'">${lang.display_name} </li>`)
          }else {
            rightMenu.append(`<li> <a class="language ${lang.html_class}" onclick="window.location.href='${href}'">${lang.display_name} </li>`)
          }
        }
      }

      let pathname = window.location.pathname
      if(langs_list.includes(pathname.split('/')[1])) {
        $('.language-label').text(pathname.split('/')[1].substring(0,2))

        if(window.name == 'nba-exp') {
          if ($('.navbar-nav-left > .nav-item > .nav-link').width() > 150 )
            $('.navbar-nav-left > .nav-item > .nav-link').addClass('--long')
        }
      }
      else
        $('.language-label').text('en')
    }
  })

function translatePaths() {
  let anchors = $('a').filter('[href^="/"]')
  for (let anchor of Array.from(anchors)) {
    $(anchor).attr('href', relativePath(langStr(locale), anchor))
  }
}

function relativePath(locale, path) {
  const href = path.href.split(window.location.origin)[1]
  const currentLang = pathLang(path.pathname)
  const end = href.split(path.pathname)[1]
  const langRegex = new RegExp(`^/${currentLang}`)
  const pathname =  currentLang != '' ? path.pathname.split(langRegex)[1] : path.pathname
  return `${locale}${pathname}${end}`
}

function pathLang(path) {
  if (path == null) { path = window.location.pathname }
  const matches = pathMatch(path)
  return matches != null ? matches[1] : ''
}

function pathMatch(path) {
  return path.match(/^\/([a-z]{2}(-[A-Z]{2})?)(\/.*|$)/)
}
