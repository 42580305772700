<template>
  <!-- <div v-if="currencies && currencies.length > 1"> -->
  <div class="dropdown coin-dropdown">
    <div
      class="dropdown-trigger"
    >
      <i
        id="coinDesktop"
        class="fa fa-usd is-hidden-mobile coin-desktop"
        @click="openMenu()"
      />
      <p
        id="coinMobile"
        class="coin-mobile"
        @click="openMenu()"
      >
        {{ $t("Currencies") }}
      </p>
    </div>
    <div
      id="dropdown-menu2"
      :class="['dropdown-menu', 'coin-menu', isVisible]"
      role="menu"
    >
      <div
        id="currency-icon"
        class="dropdown-content currency-dropdown"
      >
        <div class="dropdown-item">
          <a
            v-for="newCurrency in currencies"
            :key="newCurrency"
            class="dropdown-item currency-item"
            :value="newCurrency"
            @click="updateCurrency()"
          >
            {{ newCurrency }}
          </a>
        </div>
      </div>
    </div>
  </div>
  <!-- </div> -->
</template>

<script>

import { mapMutations } from 'vuex'
import { CurrencyIcon } from '.'
import { store } from'../../default/store'
import CurrencyApi from './currency-api'
import VueLoader from '../../default/vue-loader'


export default {

  loadCurrencyIcon() {
    let currencyIconDiv = document.getElementById('currency-icon')
    if (currencyIconDiv) {
      VueLoader.loadVueComponent(CurrencyIcon, document.getElementById('currency-icon'), store)
      VueLoader.loadVueComponent(CurrencyIcon, document.getElementById('mobile-currency'), store)
    }

  },

  props: {
    chargeablecurrencies: {
      default: undefined,
      type: Array
    }
  },
  data() {
    return {
      current: 'USD',
      visible: false,
      error_message: []
    }
  },
  computed: {
    orderCurrency(){
      return this.$store.state.order.order.currency
    },
    currencies() {
      return this.$store.state.order.currencies
    },
    isVisible(){
      return this.visible ? 'visible' : 'hidden'
    },
    order(){
      return this.$store.state.order.order
    }
  },
  watch: {
    chargeablecurrencies() {
      this.currencies = this.order.currencies
    },
    order(){
      this.current = this.order.currency
    }
  },

  created() {
    document.addEventListener('click', (e) => {
      if (this.visible && e.target.id !== 'coinDesktop' && e.target.id !== 'coinMobile') {
        this.visible = false
      }
    })
    if (this.chargeablecurrencies == undefined) {
      // Use all currencies for Event Pages
      // this.current = this.$store.state.order.currentCurrency
      this.fetchCurrencies()
    } else {
      this.updateCurrencies(this.order.currencies)
      this.current = this.order.currency
      this.setOptions()
      // Only use chargeableCurrencies for cart/order vues
    }
  },
  methods: {
    ...mapMutations('order', ['updateOrder', 'updateLoading', 'updateCurrencies', 'updateCurrentCurrency', 'updateExchangeRate']),

    ...mapMutations('order', ['updateOrder', 'updateLoading']),
    fetchCurrencies() {
      CurrencyApi.availableCurrencies().then(currencies => {
        this.currencies = currencies.currencies
        this.current = currencies.current_currency
        this.$emit('currency-emit', this.error_message, this.current, Number(currencies.rate))
      })
    },

    updateCurrency() {
      this.updateLoading(true)
      let applyCredit = this.$store.state.checkout.applyCredit
      CurrencyApi.updateOrderRatesReturnOrder(this.current, applyCredit).then((order) => {
        if (order.exchange_rate === 'rate not updated') {
          this.error_message.push('Curreny was not able to update')
          this.$emit('currency-emit', this.error_message)
          this.error_message = []
        } else {
          this.updateOrder(order)
          this.updateCurrentCurrency(order.currency)
          this.updateExchangeRate(order.exchange_rate)
          this.current = order.currency
          this.$emit('currency-emit', this.error_message, this.current, Number(this.order.exchange_rate))
        }
        this.updateLoading(false)
      })
    },

    openMenu() {
      this.visible = !this.visible
      document.getElementsByClassName('language-menu')[1].classList.remove('visible')
      document.getElementsByClassName('coin-mobile')[0].classList.toggle('hidden')
      document.getElementsByClassName('original')[2].classList.toggle('hidden')
      if(document.getElementsByClassName('account-box')) {
        document.getElementsByClassName('account-box')[0].classList.remove('visible')
      }},


    setOptions() {
      // Sets form values if they are unset.  If you use the back button, the form is rendered blank
      // 'Selected' does not affect state of form, only what is originally displayed
      // updating currencies causes form to be rendered blank
      if( document.getElementById('vue_package_id') && document.getElementById('vue_hotel_id') &&
          document.getElementById('bottom_form_customer_qty') && !document.getElementById('vue_hotel_id').value) {
        document.getElementById('vue_package_id').getElementsByTagName('option')[0].selected = 'selected'
        document.getElementById('vue_hotel_id').getElementsByTagName('option')[0].selected = 'selected'
        document.getElementById('bottom_form_customer_qty').getElementsByTagName('option')[0].selected = 'selected'
      }
    }
  }
}
</script>

<style scoped>
</style>