const SECOND = 1000
const MINUTE = SECOND * 60
const HOUR = MINUTE * 60
const DAY = HOUR * 24

function countDownTimer(date, daysElem, hoursElem, minElem, secElem) {
  let timer
  let pluralize = (text, number) => text + (number !== 1 ? 's' : '')

  let updateTime = function() {
    let timeLeft = new Date(date) - Date.now()

    if (timeLeft < 0) {
      timeLeft = 0
      clearInterval(timer)
    }

    const days = Math.floor(timeLeft / DAY)
    const hours = Math.floor((timeLeft % DAY) / HOUR)
    const minutes = Math.floor((timeLeft % HOUR) / MINUTE)
    const seconds = Math.floor((timeLeft % MINUTE) / SECOND)

    $(daysElem).html(`<span class='num'>${days}</span>` +
                     ` <span class='num-text'>${pluralize(' day', days)}</span>`)
    $(hoursElem).html(`<span class='num'>${hours}</span>` +
                     ` <span class='num-text'>${pluralize(' hour', hours)}</span>`)
    $(minElem).html(`<span class='num'>${minutes}</span>` +
                   ` <span class='num-text'>${pluralize(' minute', minutes)}</span>`)
    $(secElem).html(`<span class='num'>${seconds}</span>` +
                   ` <span class='num-text'>${pluralize(' second', seconds)}</span>`)
  }

  updateTime()
  timer = setInterval(updateTime, SECOND)
}

$(document).on('turbo:load', () =>
  $('.event-countdown').each(function() {
    countDownTimer(
      $(this).attr('data-value'),
      $(this).find('#event-countdown-days'),
      $(this).find('#event-countdown-hours'),
      $(this).find('#event-countdown-min'),
      $(this).find('#event-countdown-sec')
    )
  })
)
