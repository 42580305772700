<template>
  <div class="details payment-term-container">
    <div
      v-for="(term, index) in paymentTerms"
      :key="term.id"
      class="payment-term-info"
    >
      <PaymentTermsHeader
        :currency="currency"
        :index="index"
        :term="term"
      />

      <PassedTerm
        v-if="term.status == 'passed'"
        :index="index"
        :payment-term="term"
      />

      <CanceledTerm
        v-else-if="term.status == 'canceled'"
        :payment-term="term"
      />

      <p v-else>
        {{ unpaidText(term) }}
      </p>
    </div>
  </div>
</template>

<script>
/* global locale */

import CanceledTerm from './canceled_term'
import FetchCall from '../../../shared/local_fetch_call'
import PaymentTermsHeader from './header'
import PassedTerm from './passed_term'

export default {
  components: {
    CanceledTerm,
    PaymentTermsHeader,
    PassedTerm
  },

  data() {
    return {
      opportunityId: null,
      currency: String,
      paymentTerms: [],
    }
  },

  computed: {
    dueDate() {
      return this.date.toLocaleDateString(locale,
        { month: 'long', day: 'numeric', year: 'numeric', timeZone: 'UTC'})
    },
  },

  created() {
    let elem = document.getElementById('opportunityPaymentTermsBasicInfo')
    if(elem){
      this.currency = elem.dataset.opportunitycurrency
      this.opportunityId = elem.dataset.opportunityid
    }

    if (this.opportunityId) { this.fetchPaymentTerms() }
  },

  methods: {
    date(term) {
      if (term.due_date) {
        return new Date(term.due_date)
      } else {
        return new Date(term.charge_date)
      }
    },

    fetchPaymentTerms() {
      FetchCall.fetchWithLocale(
        `/oa/opportunities/${this.opportunityId}/payment_terms.json`,
        'GET',
        null).
        then(response => {
          if (response.payment_terms) {
            this.paymentTerms = response.payment_terms
          }
        })
    },

    unpaidText(term) {
      let d = this.date(term)

      if (d <= new Date) {
        return this.$t('paymentTerms.due_passed')
      } else {
        return this.$t('paymentTerms.due', { date: d.toLocaleDateString(locale,
          { month: 'long', day: 'numeric', year: 'numeric', timeZone: 'UTC'}) })
      }
    }
  },
}
</script>
