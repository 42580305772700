<template>
  <div
    v-if="fanaticCodes.length > 0"
    class="opportunity-fanatic-code opportunity-details-block"
  >
    <div
      :class="[expandedClass, 'opportunity-details-header']"
      @click="expanded = !expanded"
    >
      <p class="opportunity-details-header-title">
        {{ $t('fanaticCodes.yourGiftCode', { eventName: eventName }) }}
      </p>

      <span class="opportunity-details-expand-icon" />
    </div>

    <div
      :class="[expandedClass, 'opportunity-details-content']"
    >
      <p>
        {{ $t('fanaticCodes.codeHeader') }}
      </p>

      <div class="fanatic-code-values">
        <Clipboard :codes="codeValues" />
      </div>

      <div class="fanatic-code-instructions">
        <p>
          {{ $t('fanaticCodes.instructionsHeader') }}
        </p>

        <ol>
          <!-- eslint-disable vue/no-v-html -->
          <li
            v-html="$t('fanaticCodes.f1StoreLink',
                       { link: 'https://f1store.formula1.com/stores/f1' })"
          />
          <!--eslint-enable-->
          <li>
            {{ $t('fanaticCodes.selectGift') }}
          </li>

          <li>
            {{ $t('fanaticCodes.enterCode') }}
          </li>

          <li>{{ $t('fanaticCodes.saveOffPurchase') }}</li>
        </ol>
      </div>
    </div>
  </div>
</template>

<script>
import Clipboard from './click_to_copy'

export default {
  components: {
    Clipboard
  },

  data() {
    return {
      eventName: null,
      expanded: false,
      opportunityId: null,
      orderId: null,
      fanaticCodes: []
    }
  },

  computed: {
    codeValues() {
      return this.fanaticCodes.map(code => { return code.name })
    },

    expandedClass() {
      return (this.expanded == true) ? 'opened' : 'closed'
    }
  },

  created() {
    let elem = document.getElementById('opportunityFanaticCode')
    if(elem) {
      this.opportunityId = elem.dataset.opportunityid
      this.orderId = elem.dataset.orderid
      this.eventName = elem.dataset.eventname
    }
    if(this.opportunityId && this.orderId){
      this.fetchFanaticCodes()
    }
  },

  methods: {
    fetchFanaticCodes() {
      return fetch(`/oa/opportunities/${this.opportunityId}/fanatic_codes?orderId=${this.orderId}`, {
        credentials: 'same-origin',
        headers: { 'Content-Type': 'application/json' },
        method: 'GET',
      }).then(resp => {
        if (resp.status == 200) {
          return resp.json().then(body => { this.fanaticCodes = body.fanatic_codes })
        }
      })
    }
  }
}
</script>
