export default class Hotel {
  constructor(json) {
    this.address = json['Address1'] + ' ' + json['Address2'] + ' ' + json['City']
    this.address1 = json['Address1']
    this.address2 = json['Address2']
    this.amenities = json['AmenityList']
    this.checkInTime = json['CheckInTime']
    this.checkOutTime = json['CheckOutTime']
    this.city = json['City']
    this.reviews = json['TripAdvisorReviewCount']
    this.images = json['Images']
    this.latitude = json['Latitude']
    this.longitude = json['Longitude']
    this.locationDescription = json['LocationDescription']
    this.locationId = json['LocationId']
    this.ratings = json['TripAdvisorRating']
    this.reviews = json['TripAdvisorReviewCount']
    this.description = json['PropertyDescription']
    this.id = null
  }
}
