/* global hj */

import { trackPageView as gtmTrackPageView } from './google_tag_manager.js'

const forms = 'form.newsletter'
const newsletter_form_footer = 'footer form.newsletter'
const newsletter_hidden_fields = 'form.newsletter .hidden_fields'
const email = '#info_request_email, #newsletter_email, #checkout_email'

const clickedWithin = (event, field) => $(event.target).closest(field).length >= 1

$(document)

  .on('ajax:success', forms, function(e) {
    let xhr = e.detail[2]
    const modal = $(xhr.responseText).filter('.modal').modal()
    modal.on('hidden.bs.modal', () => $(this).get(0).reset())
    $(newsletter_hidden_fields).slideUp()
    hj('formSubmitSuccessful')
    if (e.target.action.indexOf('info_request') != -1 ){
      // Moved to info_request/form.vue file
    } else {
      gtmTrackPageView(`https://${window.location.host}/newsletter-thank-you`, 'Thank You News Letter', '/newsletter-thank-you')
    }
  })
  .on('ajax:error', forms, function(e) {
    let xhr = e.detail[2]
    const modal = $(xhr.responseText).filter('.modal').modal()
    modal.on('hidden.bs.modal', function() { return $(this).remove() })
  })
  .on('ajax:before', forms, function() {
    $(this).find('.btn').prop('disabled', true)
  })
  .on('ajax:complete', forms, function() {
    $(this).find('.btn').prop('disabled', false)
  })
  .on('click', function(e) {
    if (!clickedWithin(e, newsletter_form_footer) && !clickedWithin(e, '.modal')) {
      $(newsletter_hidden_fields).slideUp()
    }
  })
  .on('blur', email, function() {
    const hint = $('#hint')
    hint.css('display', 'none').empty()
    $(this).mailcheck({
      suggested(element, suggestion) {
        if (!hint.html()) {
          // First error - fill in/show entire hint element
          const suggestions = `Did you mean <span>${suggestion.address}</span>@<span>${suggestion.domain}</span>?`
          hint.html(suggestions).fadeIn(150)
        } else {
          // Subsequent errors
          $('.address').html(suggestion.address)
          $('.domain').html(suggestion.domain)
        }
      }
    })

  })
  .on('turbo:load', function() {
    $(newsletter_form_footer).find('.email').focus(function() {
      if ($(newsletter_hidden_fields).is(':hidden')) {
        $(newsletter_hidden_fields).slideDown()
      }
    })

    // Reveals hidden forms on button click
    $('#slide-down-button').click(() => $('.slide-down-container').slideDown('slow'))

    if ($('[data-event-id]').length) {
      $('#info_request_event_id').val($('[data-event-id]').data('eventId'))
    }
  })
