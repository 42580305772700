<template>
  <div class="event">
    <input
      v-model="phrase"
      class="form-control"
      type="text"
      placeholder="Search"
    >
    <table>
      <tr
        v-for="event in filteredEvents"
        :key="event.id"
      >
        <td>
          <a :href="`/terms_and_conditions/${event.id}`">{{ event.name }}</a>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import FetchCall from '../local_fetch_call'

export default {
  data() {
    return {
      events: [],
      phrase: ''
    }
  },

  computed: {
    filteredEvents() {
      return this.events.filter(event => {
        return event.name.toLowerCase().indexOf(this.phrase.toLowerCase()) > -1
      })
    }
  },

  async mounted() {
    this.events = await FetchCall.fetchWithoutLocale(
      '/api/terms_and_conditions',
      'GET',
      null
    )
  }
}
</script>
