import * as Sentry from '@sentry/browser'
Sentry.init({
  dsn: 'https://d0eafed42bfc49bd95b1287adfacfe6e@o1037737.ingest.sentry.io/6005818',

  release: null,

  integrations: [Sentry.browserTracingIntegration()],

  // uncomment if you want to enable calls to sentry
  // replaysSessionSampleRate: 1.0,
  // replaysOnErrorSampleRate: 1.0,
  // integrations: [
  //   new Sentry.Replay({
  //     // Additional SDK configuration goes in here, for example:
  //     maskAllText: true,
  //     blockAllMedia: true,
  //   }),
  // ],



  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  sampleRate: 0.1,
  tracesSampleRate: 0.01,
// ...
})


