const validatorOptions = {
  rules: {
    'customer[email]': {
      required: true,
      emailTest: true
    },
    'customer[password]': {
      required: true,
      minlength: 8
    },
    'customer[password_confirmation]': {
      required: true,
      minlength: 8,
      equalTo: 'main #customer_password'
    },
    'customer[rewards_acceptance]': {
      required: true
    }
  },
  messages: {
    'customer[password_confirmation]': {
      equalTo: 'Confirmation must match password'
    }
  },
  success(element) {
    $(element).remove().prev().removeClass('formError')
  },
  highlight(element) {
    $(element).closest('.form-group').addClass('has-error')
  },
  unhighlight(element) {
    $(element).closest('.form-group').removeClass('has-error')
  },
  errorPlacement(error, element) {
    $(element).parent().append(error)
  }
}

const editRules = {
  rules: {
    'customer[email]': {
      required: true,
      emailTest: true
    },
    'customer[current_password]': {
      required: true
    },
    'customer[password]': {
      minlength: 8
    },
    'customer[password_confirmation]': {
      minlength: 8,
      equalTo: 'main #customer_password'
    }
  },
  messages: {
    'customer[password_confirmation]': {
      equalTo: 'Confirmation must match password'
    }
  }
}

$(document)
  .on('turbo:load', function() {
    $('.simple_form.edit_customer').validate($.extend(validatorOptions, editRules))
    $('.simple_form.guest').validate(validatorOptions)
    $('.simple_form.new_customer').validate(validatorOptions)
  })
  // .on('ajax:error', '.second-account-form', (e) => {
  //   I don't think we're using this anymore because form was moved to vue
  //   let data = e.detail[0]
  //   return $('.login-form .response').html($(data).text())
  // })
  // .on('ajax:error', '.account-form', (e) => {
  //   I don't think we're using this anymore because form was moved to vue
  //   let data = e.detail[0]
  //   return $('.account-box .response').html($(data).text())
  // })
  .on('click', '.no-cust a', function(e) {
    e.preventDefault()
    $('.navbar-toggle').click()
    setTimeout((() => $('.account-dropdown').toggleClass('open')), 500)
  })
  .on('click', 'body', function(e) {
    if (!$(e.target).closest('.account-dropdown')) {
      $('.account-dropdown').removeClass('open')
    }
  })
