<template>
  <div
    v-if="tvCodes.length > 0"
    class="opportunity-f1-tv-code opportunity-details-block"
  >
    <div
      :class="[expandedClass, 'opportunity-details-header']"
      @click="expanded = !expanded"
    >
      <p class="opportunity-details-header-title">
        {{ $t('f1TvCodes.f1_access_codes') }}
      </p>

      <span class="opportunity-details-expand-icon" />
    </div>

    <div :class="[expandedClass, 'opportunity-details-content']">
      <!-- eslint-disable vue/no-v-html -->
      <p
        class="f1-tv-instructions"
        v-html="$t('f1TvCodes.instructions_link',
                   { 'link': 'https://f1experiences.com/f1-tv-access-instructions' })"
      />
      <!--eslint-enable-->
      <div class="f1-tv-codes">
        <Clipboard :codes="codeValues" />
      </div>
    </div>
  </div>
</template>

<script>
import Clipboard from './click_to_copy'

export default {
  components: {
    Clipboard
  },

  data() {
    return {
      expanded: false,
      opportunityId: null,
      orderId: null,
      tvCodes: []
    }
  },

  computed: {
    codeValues() {
      return this.tvCodes.map(code => { return code.name })
    },

    expandedClass() {
      return (this.expanded == true) ? 'opened' : 'closed'
    }
  },

  created() {
    let elem = document.getElementById('opportunityF1TvCode')
    if(elem) {
      this.opportunityId = elem.dataset.opportunityid
      this.orderId = elem.dataset.orderid
    }
    if(this.opportunityId && this.orderId){
      this.fetchTvCodes()
    }
  },

  methods: {
    fetchTvCodes() {
      return fetch(`/oa/opportunities/${this.opportunityId}/f1_access_codes?orderId=${this.orderId}`, {
        credentials: 'same-origin',
        headers: { 'Content-Type': 'application/json' },
        method: 'GET',
      }).then((resp) => {
        if (resp.status == 200) {
          return resp.json().then((body) => { this.tvCodes = body.f1_access_codes })
        }
      })
    }
  }
}
</script>
