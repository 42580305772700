let events = []
let subcat = true
let buttons = []
let param = null

function showEvents(category) {
  events.addClass('hide')
  for (let event of Array.from(events)) {
    if (Array.from($(event).data('categories')).includes(category)) {
      $(event).removeClass('hide')
    }
  }
  if (buttons[0]) { buttons.removeClass('selected') }
  $('.category .btn.btn-secondary').removeClass('selected')
  for (let btn of Array.from(buttons)) {
    if ($(btn).data('category') === category) { $(btn).addClass('selected') }
  }
  if (subcat) {
    $('.event-list').addClass('hide')
  }
}

function activeButton() {
  const selected = $('.btn-primary.selected')
  if (selected.length !== 1) { return false }
  return selected.data('category')
}

function prepareEvents() {
  events = $('.sub-cat')
  if (events.length < 1) {
    events = $('.event-item')
    subcat = false
  } else {
    subcat = true
    buttons = $('.category .btn.btn-primary')
    events.addClass('hide')
  }
  param = urlParam()
}


function urlParam() {
  const results = new RegExp('[\\?&]category=([^&#]*)').exec(window.location.href)
  if (results) { return decodeURIComponent(results[1]) }
}

$(document)
  .on('turbo:load', function() {
    prepareEvents()
    if (param) { showEvents(param) }
    if (activeButton()) { return showEvents(activeButton()) }
  })
  .on('click', '.category .btn.btn-primary', function() {
    prepareEvents()
    let category = $(this).data('category')
    return showEvents(category)
  })
  .on('click', '.category .btn.btn-secondary', function() {
    prepareEvents()
    if (subcat) {
      events.addClass('hide')
      $('.event-list').removeClass('hide')
      buttons.addClass('selected')
      $('.category .btn.btn-secondary').addClass('selected')
    } else {
      events.each(function() { $(this).removeClass('hide') })
    }
  })
