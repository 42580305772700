<template>
  <div :class="[loading ? 'loading-card' : '', 'stripe-card-form']">
    <div class="loading-icon" />

    <div class="card-fields">
      <div class="card-field-group card-number">
        <div
          id="card-number"
          class="input-field"
        />

        <div
          id="cardErr"
          class="card-errors"
        />
      </div>

      <div class="card-field-group card-cvc">
        <div
          id="card-cvc"
          class="input-field"
        />

        <div
          id="cvcErr"
          class="card-errors"
        />
      </div>

      <div class="card-field-group card-exp">
        <div
          id="card-exp"
          class="input-field"
        />

        <div
          id="cardExpErr"
          class="card-errors"
        />
      </div>

      <div
        id="card-errors"
        class="card-errors"
      />
    </div>

    <button
      class="continue-button btn btn-primary"
      @click="generateCardId"
    >
      {{ buttontext }}
    </button>
  </div>
</template>

<script>
import FetchCall from '../local_fetch_call'
import StripeJS from './stripe.js'

export default {
  props: {
    address: {
      default: null,
      required: true,
      type: String,
    },

    address2: {
      default: null,
      required: false,
      type: String,
    },

    buttontext: {
      default: null,
      required: true,
      type: String,
    },

    city: {
      default: null,
      required: true,
      type: String,
    },

    name: {
      default: null,
      required: true,
      type: String,
    },

    phone: {
      default: null,
      required: false,
      type: String,
    },

    state: {
      default: null,
      required: false,
      type: String,
    },

    zipcode: {
      default: null,
      required: true,
      type: String,
    }
  },

  data() {
    return {
      clientSecret: null,
      displayError: null,
      loading: false,
    }
  },

  computed: {
    billingDetails() {
      return {
        address: {
          city: this.city || '',
          line1: this.address || '',
          line2: this.address2 || '',
          postal_code: this.zipcode || '',
          state: this.state || ''
        },
        name: this.name || '',
        phone: this.phone || ''
      }
    },
  },

  mounted() {
    StripeJS.generateForm()
    this.createClientSecret()
  },

  methods: {
    attachToCustomer(cardId) {
      let body = JSON.stringify({ id: cardId })

      FetchCall.fetchWithLocale('/oa/payment_methods.json', 'POST', body).
        then(card => {
          if (card.id) {
            this.$emit('card-added', { card: card, stripe: StripeJS })
          } else {
            this.createClientSecret()
            StripeJS.addErrorMessage(this.$t('paymentTerms.error_saving'))
            this.loading = false
          }
        })
    },

    createClientSecret() {
      FetchCall.fetchWithLocale('/oa/setup_intents.json', 'POST', null).
        then(response => {
          if (response.client_secret) { this.clientSecret = response.client_secret }
        })
    },

    generateCardId() {
      if (this.loading) { return }

      this.loading = true
      StripeJS.cardIDToBeChargedLater(this.clientSecret, this.billingDetails).
        then(cardId => {
          if (cardId === 'error') {
            // Stripe will generate errors here
            this.createClientSecret()
            this.loading = false
          } else {
            this.attachToCustomer(cardId)
          }
        })
    }
  },
}
</script>
