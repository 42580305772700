import ScrollClass from '../shared/scrolling_class'
import { tooltipSetter } from '../shared/tooltip'
import { CurrencyDropdown } from './currency_convert'
import VueLoader from './vue-loader'
import { store } from'../default/store'
window.tooltipReference = null
window.window.hoverable = true

const tabs = '.category-seating-chart .group-tabs a'
$.tableRowsList = categoryName =>
  $(`[data-category-names*='${categoryName}']`).filter(function() {
    return $(this).data('categoryNames').indexOf(categoryName) > -1
  })


$.changeTableColors = function() {
  $('.replaceable-table tr').removeClass('row-color')
  $('.replaceable-table tr').not('.hidden').filter(':even').addClass('row-color')
}

function urlParam(name) {
  const results = new RegExp(`[\\?&]${name}=([^&#]*)`).exec(window.location.href)
  if (results) { return decodeURIComponent(results[1]) }
}

function updateImages(categoryName) {
  const image = $(`[data-image-group*='${categoryName}']`).filter(function() {
    return $(this).data('imageGroup').indexOf(categoryName) > -1
  })

  if ($(image)[0]) {
    $('.tab-content .tab-pane').removeClass('active')
    $(image).addClass('active')
  }
}

function updateTable(categoryName, scrollClass) {
  $('.ticket-package-table').addClass('hidden')
  $('.replaceable-table tr').addClass('hidden')

  updateImages(categoryName)

  if ($.tableRowsList(categoryName)[0]) {
    $('.ticket-package-table').removeClass('hidden')
    ScrollClass.scrollToClass(scrollClass)
    $.tableRowsList(categoryName).removeClass('hidden')
    $.changeTableColors()
  }
}

function activatePackage(tag) {
  Array.from(tag.parentNode.children).forEach(element => {
    if(element.classList.contains('active')){
      element.classList.remove('active')
    }
  })
  if(!tag.classList.contains('active')) {
    tag.classList.add('active')
  }
}

function hidePackages() {
  if(window.brand_name == 'NBA Experiences'){ return }
  let pkg_grp = urlParam('package-group')
  if (pkg_grp) {
    filterPackages(pkg_grp)
  }
}

function filterPackages(pkg_grp) {
  $('.seatingChartPackage').each(function () {
    let categories = $(this).attr('data-category-names').split(',')
    let hide = true
    for (let category of categories) {
      if (category == pkg_grp) {
        hide = false
      }
    }
    if (hide) {
      $(this).addClass('hidden')
    }
  })
}

$(window)
  .on('load', function () {
    if ($('.product-item').length > 0 && window.brand_name=='Grand Prix Events') {
      $('.event-link').attr('data-turbolinks', false)
      var currentOffset = []
      const gpe_nav_height = 70

      Array.from(document.querySelectorAll('.ticket-table-head')).forEach((elem, index) => {
        var tmp = document.createElement('div')
        $(tmp).addClass('extra')
        $(tmp).css({height: $(elem).height(), visibility: 'hidden'})

        currentOffset.push(elem.offsetTop)

        document.addEventListener('scroll', () => {
          window.hoverable = false
          var scrollLength = ($(window).scrollTop())

          let lchild = elem.parentNode.children[elem.parentNode.children.length - 1]
          let pos = lchild.offsetTop + lchild.offsetHeight - gpe_nav_height - $(elem).height()

          if (scrollLength > currentOffset[index] && scrollLength >= pos) {
            $(elem).css({position: 'fixed'})

            let val = scrollLength - pos
            let top_val = 0

            // val < 0 always returns false
            // if (val < 0) {
            //   top_val = gpe_nav_height + val
            // } else {
            top_val = gpe_nav_height - val
            // }

            $(elem).css({top: top_val})

          } else if (scrollLength + gpe_nav_height >= currentOffset[index]) {
            elem.parentNode.insertBefore(tmp, elem.parentNode.childNodes[0])

            $(elem).css({position: 'fixed', top: '70px', width: $(elem).width(), zIndex: '1'})

          } else {
            if (elem.parentNode.contains(tmp)) {
              elem.parentNode.removeChild(tmp)
            }

            $(elem).css({position: 'relative', top: '', width: '100%', zIndex: '0'})
          }
          clearTimeout($.data(this, 'trueTimer'))
          $.data(this, 'trueTimer', setTimeout(function() {
            window.hoverable = true
            tooltipSetter(window.tooltipReference)
          }, 500))
        }, 0)
      })
    }
  })




$(document)
  .on('turbo:load', function() {
    $('.category-seating-chart .ticket-package-table, .category-seating-chart .replaceable-table tr')
      .addClass('hidden')

    const category = urlParam('package-group') ||
      $('.category-seating-chart .group-tabs .active').data('categoryName')

    if (category) { updateTable(category, 'scroll-on-page-load') }
    hidePackages()
  })
  .on('click', tabs, function() {
    activatePackage(this)
    updateTable($(this).data('categoryName'), 'scroll-on-click')
  })
  .on('click', '.minus', function() {
    var $input = $(this).parent().find('input')
    var count = parseInt($input.val()) - 1
    count = count < 1 ? 1 : count
    $input.val(count)
    $input.change()
  })
  .on('click', '.plus', function() {
    var $input = $(this).parent().find('input')
    $input.val(parseInt($input.val()) + 1)
    $input.change()
    return false
  })


document.addEventListener('turbo:load', () => {
  let dropdowns = null

  if(window.name == 'qah')
    dropdowns = document.querySelectorAll('.table-wrapper .currency-dropdown')
  else
    dropdowns = document.querySelectorAll('.ticket-table-head .currency-dropdown')

  for (let dropdown of dropdowns) {
    VueLoader.loadVueComponent(CurrencyDropdown, dropdown, store)
  }
})

export {urlParam}
