<template>
  <div>
    <div>
      <p
        v-if="anyChargeablePaymentTerms"
        class="details payment-term-details charge-date"
      >
        <button
          class="btn btn-secondary add-new-card"
          @click="changeCard"
        >
          {{ $t('paymentTerms.addCreditCard') }}
        </button>
      </p>

      <div class="details payment-term-container">
        <div
          v-for="(term, index) in paymentTerms"
          :key="term.id"
          class="payment-term-info"
        >
          <PaymentTermsHeader
            :currency="currency"
            :index="index"
            :term="term"
          />

          <PassedTerm
            v-if="term.status == 'passed' && !term.change_order_duplicate"
            :index="index"
            :payment-term="term"
          />

          <CanceledTerm
            v-else-if="term.status == 'canceled' || term.change_order_duplicate"
            :payment-term="term"
          />

          <ChargeableTerm
            v-else
            :payment-term="term"
            :opportunityid="opportunity.id"
            @pay-now="payNow"
          />
        </div>
      </div>
    </div>

    <CreditCardForms
      :adding-card="addingCard"
      :opportunity="opportunity"
      :paying-now="payingNow"
      :payment-terms="paymentTerms"
      :payment-term-to-charge="paymentTermToCharge"
      @close="closeCardForms"
      @refresh="updatePaymentTerms"
    />
  </div>
</template>

<script>
import CanceledTerm from './canceled_term'
import ChargeableTerm from './chargeable_term'
import CreditCardForms from './credit_card_forms'
import FetchCall from '../../../shared/local_fetch_call'
import PaymentTermsHeader from './header'
import PassedTerm from './passed_term'
import OpenPayment from '../opportunities/open_payment'

export default {
  components: {
    CanceledTerm,
    ChargeableTerm,
    CreditCardForms,
    PaymentTermsHeader,
    PassedTerm,
  },

  data() {
    return {
      addingCard: false,
      currency: String,
      payingNow: false,
      paymentTerms: [],
      paymentTermToCharge: {},
      opportunity: null
    }
  },

  computed: {

    anyChargeablePaymentTerms() {
      let allStatuses = this.paymentTerms.map(term => { return term.status })

      if (allStatuses.length < 1) {
        return false
      } else {
        let passedTerms = this.paymentTerms.filter(term => { return term.status == 'passed' })
        return allStatuses.length > passedTerms.length
      }
    },
  },

  created() {
    let elem = document.getElementById('opportunityPaymentTermsToCharge')
    let id = elem.dataset.opportunityid
    if(elem){
      this.currency = elem.dataset.opportunitycurrency
    }

    if (id) {
      this.fetchOpportunity(id).then(opportunity => {
        if (opportunity.id) {
          this.opportunity = opportunity
          this.fetchPaymentTerms()
        }
      })
    }
  },

  methods: {

    changeCard() {
      if(document.getElementById('admin-logout')) {
        return false
      } else {
        this.paymentTermToCharge = {}
        this.payingNow = false
        this.addingCard = true
      }
    },

    closeCardForms() {
      this.paymentTermToCharge = {}
      this.addingCard = false
      this.payingNow = false
    },

    fetchOpportunity(id) {
      return FetchCall.fetchWithLocale(`/customer/experiences/${id}.json`, 'GET', null).
        then(opportunity => {
          if (opportunity.id) { this.opportunity = opportunity }
          return opportunity
        })
    },

    fetchPaymentTerms() {
      FetchCall.fetchWithLocale(`/oa/opportunities/${this.opportunity.id}/payment_terms.json`,
        'GET',
        null).
        then(response => {
          if (response.payment_terms) {
            this.paymentTerms = response.payment_terms
            this.openPaymentTermsIfFirstUnpaid()
            this.findPastDuePayments()
          }
        })
    },

    payNow(paymentTerm) {
      this.paymentTermToCharge = paymentTerm
      this.addingCard = false
      this.payingNow = true
    },

    findPastDuePayments(){
      let paymentDetailsDiv = document.getElementsByClassName('opportunity-payment-terms')[0]
      this.paymentTerms.forEach(paymentTerm => {
        if (this.pastDueDate(paymentTerm) && this.failedStatus(paymentTerm)){
          OpenPayment.openPayment()
          paymentDetailsDiv.classList.add('past-due')
        }
      })
    },

    pastDueDate(paymentTerm){
      return new Date(paymentTerm.due_date) <= new Date()
    },

    failedStatus(paymentTerm){
      return (paymentTerm.status == 'pending' || paymentTerm.status == 'failed')
    },

    openPaymentTermsIfFirstUnpaid(){
      let ptStatus = this.paymentTerms[0].status
      if(ptStatus == 'pending' || ptStatus == 'failed'){
        OpenPayment.openPayment()
      }
    },

    updatePaymentTerms() {
      this.closeCardForms()
      this.fetchPaymentTerms()
    }
  }
}
</script>
