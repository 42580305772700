<template>
  <transition name="slide">
    <div
      v-if="addingCard || payingNow"
      class="opportunity-show-credit-card-form"
    >
      <div class="credit-card-form-section">
        <AddNewCard
          v-if="addingCard"
          :opportunity="opportunity"
          :payment-terms="paymentTerms"
          @close="$emit('close')"
          @refresh="$emit('refresh')"
        />

        <PayNow
          v-if="payingNow"
          :opportunity="opportunity"
          :payment-term="paymentTermToCharge"
          @close="$emit('close')"
          @refresh="$emit('refresh')"
        />
      </div>

      <span
        class="empty-section"
        @click="$emit('close')"
      />
    </div>
  </transition>
</template>


<script>
import AddNewCard from './add_new_card_form'
import PayNow from './pay_now_form'

export default {
  components: {
    AddNewCard,
    PayNow
  },

  props: {
    addingCard: {
      default: false,
      required: true,
      type: Boolean
    },

    opportunity: {
      default: () => {},
      type: Object
    },

    paymentTerms: {
      default: () => [],
      type: Array,
    },

    paymentTermToCharge: {
      default: null,
      type: String
    },

    payingNow: {
      default: false,
      required: true,
      type: Boolean
    }
  }
}
</script>
