<template>
  <div v-if="refundTotal > 0">
    <p
      v-for="refund in refunds"
      :key="refund.id"
    >
      {{ $t('paymentTerms.refund',
            { amount: refund.amount_decorated, date: dateConvert(refund.created_at) }) }}
    </p>
  </div>
</template>

<script>
/* global locale */

import FetchCall from '../../../shared/local_fetch_call'

export default {
  name: 'RefundsList',
  props: {
    paymenttermid: {
      default: null,
      required: false,
      type: Number
    }
  },

  data() {
    return {
      refunds: [],
      refundTotal: 0
    }
  },

  created() {
    if (this.paymenttermid) { this.fetchRefunds() }
  },

  methods: {
    dateConvert(date) {
      return new Date(date).toLocaleDateString(locale,
        { month: 'long', day: 'numeric', year: 'numeric', timeZone: 'UTC'})
    },

    fetchRefunds() {
      FetchCall.fetchWithLocale(
        `/oa/payment_terms/${this.paymenttermid}/refunds.json`, 'GET', null).
        then(response => {
          if (response.refund_total && response.refund_total > 0) {
            this.refundTotal = response.refund_total
            this.refunds = response.refunds
          }
        })
    },
  }
}
</script>
