function bynder(){
  let bynderElement = document
    .getElementById('loadBynder')
  if(bynderElement){
    let __lc = {}
    window.__lc = __lc
    let a = $('[data-bynder-widget]')[0]
    let lc = document.createElement('script')
    lc.id = 'bynder-widgets-js'
    lc.setAttribute('data-account-url', 'assets.quintevents.com')
    lc.setAttribute('data-language', navigator.language || 'en-US')
    lc.type = 'text/javascript'
    lc.src = 'https://d8ejoa1fys2rk.cloudfront.net/bynder-embed/latest/bynder-embed.js'
    a.appendChild(lc)
  }
}
$(document).on('turbo:load', function() {
  bynder()
})
