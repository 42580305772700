<template>
  <div class="search-reservations-history-container">
    <form
      class="reservations-search-form"
      @submit.prevent="submitForm"
    >
      <input
        v-model="hotel_name"
        class="form-control"
        placeholder="Search hotels..."
      >

      <div class="buttons-search-reset">
        <button
          type="submit"
          class="btn btn-primary search"
        >
          Go
        </button>

        <button
          type="button"
          class="btn btn-secondary reset"
          @click.prevent="resetSearch"
        >
          Reset
        </button>
      </div>
    </form>
  </div>
</template>
<script>
import FetchCall from '../../shared/local_fetch_call'
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      hotel_name: '',
    }
  },

  methods: {
    ...mapGetters('reservation', ['getAllReservationsObj']),

    submitForm() {
      this.fetchReservations()
    },

    fetchReservations() {
      FetchCall.fetchWithoutLocale(
        '/api/hotel_reservations/search_history.json?hotel_name=' + this.hotel_name,
        'GET',
        null
      ).then((response) => {
        this.$emit('searchResult', response)
      })
    },

    resetSearch() {
      this.$emit('searchResult', this.getAllReservationsObj())
      this.hotel_name = null
    }
  },
}
</script>
