import 'owl.carousel'
import { addOwlCarousels } from './carousel'
import { loadBlogElements } from '../f1/blog_slide_elems'

const csrfToken = () => {
  let tok = document.getElementsByTagName('meta')['csrf-token']
  if (tok) { // csrf-token is null in testing
    return tok.getAttribute('content')
  } else {
    return null
  }
}

function setupIndicies() {
  let blogIndicies = Array.from(document.getElementsByClassName('topicShow'))
  blogIndicies.forEach(async index => {
    await loadIndex(index)
  })
}

function updatePageButtons() {
  let blogPageButtons = Array.from(document.getElementsByClassName('blog-next-page-button'))
  blogPageButtons.forEach(button => {
    let id = button.dataset.targetTopicId
    let index = document.querySelector(`[data-topic-id='${id}']`)
    let lastPage = index.querySelector('[data-last-page=\'true\']')
    if (lastPage == null){
      button.onclick = function() { nextPage(index) }
    } else {
      button.classList.add('hide')
    }
  })
  let blogPrevPageButtons = Array.from(document.getElementsByClassName('blog-prev-page-button'))
  blogPrevPageButtons.forEach(button => {
    let id = button.dataset.targetTopicId
    let index = document.querySelector(`[data-topic-id='${id}']`)
    let page = parseInt(index.dataset.page)
    if (page > 1) {
      button.onclick = function() { prevPage(index) }
    } else {
      button.classList.add('hide')
    }
  })
}

async function loadIndex(index) {
  const id = index.dataset.topicId
  let resp = await fetchTopic(id, 1, index.dataset.topicPerPage)
  let body = await resp.text()
  if (resp.status == 200){
     
    index.dataset.page = 1
    updateBody(index, body)
  }
}

async function nextPage(index) {
  const id = index.dataset.topicId
  let page = parseInt(index.dataset.page) + 1
  let resp = await fetchTopic(id, page, index.dataset.topicPerPage)
  let body = await resp.text()
  if (resp.status == 200){
     
    index.dataset.page = page
    updateBody(index, body)
    window.scrollTo({top: 120})
  }
}

async function prevPage(index) {
  const id = index.dataset.topicId
  let page = parseInt(index.dataset.page) - 1
  let resp = await fetchTopic(id, page, index.dataset.topicPerPage)
  let body = await resp.text()
  if (resp.status == 200){
     
    index.dataset.page = page
    updateBody(index, body)
    window.scrollTo({top: 120})
  }
}

function hidePageButtons() {
  let blogPageButtons = Array.from(document.getElementsByClassName('blog-next-page-button'))
  blogPageButtons.forEach(button => {
    button.classList.add('hide')
  })
  let blogPrevPageButtons = Array.from(document.getElementsByClassName('blog-prev-page-button'))
  blogPrevPageButtons.forEach(button => {
    button.classList.add('hide')
  })

}

function updateBody(index, body) {
  index.innerHTML = body

  if(window.name == 'f1') {
    hidePageButtons()
    loadBlogElements()
  } else {
    updatePageButtons()
  }

  addOwlCarousels()
}

async function fetchTopic(id, page, count){
  let path = `/api/topics/${id}?page=${page}&count=${count}&locale=${window.locale}`
  return fetch(path,
    {
      credentials:  'same-origin',
      headers: {
        'Content-Type': 'text/html',
        'X-CSRF-TOKEN': csrfToken()
      }
    })
}

document.addEventListener('turbo:load', setupIndicies)
