$(document).on('turbo:load', function() {
  // CONFIGS
  let targetBreakpoint = 769
  let currentVisibleColumn = 1
  let nextButtonText = 'COMPARE NEXT'

  // SETUP/SELECT REUSABLE ELEMENTS
  let table = $('.comparison-table')[0]
  let newPackageComparisonTable = $('.package-comparison')[0]
  if (table === undefined || newPackageComparisonTable) { return }
  let allCells = table.querySelectorAll('th, td')
  let columnHeaders = table.querySelectorAll('thead th:not(:empty)')
  let rowHeaders = table.querySelectorAll('tbody th')
  let nextButton = document.createElement('button')

  function createButtons() {
    nextButton.className = 'btn btn-primary'
    nextButton.textContent = nextButtonText
    nextButton.style.display =  'none'

    table.parentNode.insertBefore(nextButton, table.nextSibling )

    nextButton.addEventListener('click', function(){
      currentVisibleColumn = currentVisibleColumn + 1 > columnHeaders.length ? 1 : currentVisibleColumn + 1
      showCurrentlyVisible()
    })
  }

  function showCurrentlyVisible() {
    // Get the Items we're going to show. The :not(:empty) query here is because sometimes you have empty <th>s in <thead>
    let currentlyVisibleColHeader = document.querySelector('thead th:not(:empty):nth-of-type( '+ currentVisibleColumn +')')
    let currentlyVisibleCells = document.querySelectorAll('tbody td:nth-of-type(' +currentVisibleColumn+ ')')

    // Hide All The Cells
    Array.from(allCells).forEach(function(cell) {
      cell.style.display = 'none'
    })

    // Show Currently Visible Col Header
    currentlyVisibleColHeader.style.display = 'block'

    // Show Currently Visible Cells
    Array.from(currentlyVisibleCells).forEach(function(cell) {
      cell.style.display = 'inline-block'
      cell.style.width = '24%'
    })

    // Show Row Headers
    Array.from(rowHeaders).forEach(function(cell, index) {
      rowHeaders[index].style.display = 'inline-block'
      rowHeaders[index].style.width = '74%'
    })
  }

  function updateTable() {

    // Get the Table's Width. Might as well go FULL Container Query over here.
    let tableWidth = table.getBoundingClientRect().width

    // If the table explodes off the viewport or is wider than the target breakpoint
    if ( tableWidth > window.innerWidth || tableWidth < targetBreakpoint ) {
      if(table.getAttribute('data-comparing') != 'active') {
        // Set the comparison state to "Active"
        table.setAttribute('data-comparing','active')

        // Show Next Button
        nextButton.style.display =  'block'

        // Show the currently visible column
        showCurrentlyVisible()
      }
    }

    if(table.getAttribute('data-comparing') == 'active' && window.innerWidth >= targetBreakpoint) {
      // Turn off comparing
      table.setAttribute('data-comparing', '')

      // Hide the next button
      nextButton.style.display =  'none'

      // Remove styles from all cells, ergo, show all the cells
      Array.from(allCells).forEach(function(cell) {
        cell.style.display = ''
        cell.style.width = ''
      })

      Array.from(rowHeaders).forEach(function(cell) {
        cell.style.display = ''
        cell.style.width = ''
      })
    }
  }

  createButtons()
  updateTable()
  window.addEventListener('resize', updateTable)
})
