<template>
  <select
    v-model="selected"
    class="sort-by-filter"
  >
    <option value="">
      {{ $t('seatingChart.sortBy') }}
    </option>
    <option
      v-for="(option, id) in options"
      :key="id"
      :value="option"
    >
      {{ option.value }}
    </option>
  </select>
</template>

<script>
export default {
  data() {
    return {
      options: [
        {id: 1, value: this.$t('seatingChart.lowToHigh')},
        {id: 2, value: this.$t('seatingChart.highToLow')},
        {id: 3, value: this.$t('seatingChart.bestSelling')},
      ],
      selected: ''
    }
  },

  watch: {
    selected() {
      if (this.selected.id == null) return

      if (this.selected.id == 1) {
        for (let group of $('.group-packages')) {
          let group_id = group.dataset.groupId
          let pkg = '.group-' + group_id + ' .seatingChartPackage'

          $(pkg).sort(function (a, b) {
            return a.getElementsByClassName('product-item')[0].dataset.price
              - b.getElementsByClassName('product-item')[0].dataset.price
          }).appendTo($('.group-' + group_id))
        }
      } else if (this.selected.id == 2) {
        for (let group of $('.group-packages')) {
          let group_id = group.dataset.groupId
          let pkg = '.group-' + group_id + ' .seatingChartPackage'

          $(pkg).sort(function (a, b) {
            return b.getElementsByClassName('product-item')[0].dataset.price
              - a.getElementsByClassName('product-item')[0].dataset.price
          }).appendTo($('.group-' + group_id))
        }
      } else if (this.selected.id == 3) {
        for (let group of $('.group-packages')) {
          let group_id = group.dataset.groupId
          let pkg = '.group-' + group_id + ' .seatingChartPackage'

          $(pkg).sort(function (a, b) {
            return b.dataset.categoryNames.includes('Best Selling')
            - a.dataset.categoryNames.includes('Best Selling')
          }).appendTo($('.group-' + group_id))
        }
      }
    },
  }
}
</script>
