import smoothscroll from 'smoothscroll-polyfill'

export default {
  scrollToClass(className) {
    let divs = document.getElementsByClassName(className)

    if (divs[0]) {
      smoothscroll.polyfill()
      divs[0].scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }
}
