function zopim_chat() {
  $('[__jx__id], embed#__zopnetworkswf').remove()
  window.zEACLoaded = false
  window.$zopim = null;
  (function (d, s) {
    var z =
      (window.$zopim = function (c) {
        z._.push(c)
      })

    const $ = (z.s = d.createElement(s))
    const e = d.body.getElementsByTagName(s)[0]

    z.set = function (o) {
      z.set._.push(o)
    }

    z._ = []
    z.set._ = []
    $.defer = true
    $.setAttribute('charset', 'utf-8')
    $.src = '//static.zdassets.com/ekr/snippet.js?key=0a806383-292a-4db8-8586-1081efe7a545'
    z.t = +new Date
    $.id = 'ze-snippet'
    $.type = 'text/javascript'

    if (e) { e.parentNode.insertBefore($, e) } else { document.body.appendChild($) }

    window.$zopim(function () {
      window.$zopim.livechat.departments.filter('')
      window.$zopim.livechat.departments.setVisitorDepartment(
        window.abbreviation
      )
      if (/^#[0-9A-F]{6}$/i.test(window.chat_color)) {
        window.$zopim.livechat.theme.setColor(window.chat_color)
      }
    })
  })(document, 'script')
}

// $(document).on('turbo:load', zopim_chat)
$(document).on('turbo:load', () => {
  if (window.chat_service === 'zopim')
    zopim_chat()
})
