<template>
  <div class="opportunity-show-payment-form">
    <div class="close-button">
      <button
        class="btn btn-secondary"
        @click="close"
      >
        {{ $t('navCart.close') }}
      </button>
    </div>

    <p
      v-if="errs"
      class="errors"
      @click="errs = null"
    >
      {{ errs }}
    </p>

    <CreditCardForm
      v-if="!processing"
      :address="opportunity.street"
      :buttontext="buttonText"
      :city="opportunity.city"
      :name="opportunity.name"
      :phone="opportunity.phone"
      :state="opportunity.state"
      :zipcode="opportunity.zip"
      includechecks="false"
      @card-added="payNow"
    />

    <div
      v-if="processing"
      class="stripe-card-form loading-card"
    >
      <div class="loading-icon" />

      <button
        class="continue-button btn btn-primary processing-payment"
        disabled
      >
        {{ $t('paymentTerms.processing_amount', { amount: paymentTerm.amount_decorated }) }}
      </button>
    </div>
  </div>
</template>

<script>
import CreditCardForm from '../../../shared/stripe_form/credit_card_form.vue'
import FetchCall from '../../../shared/local_fetch_call'
import { mapMutations } from 'vuex'

export default {
  components: {
    CreditCardForm
  },

  props: {
    opportunity: {
      default: () => {},
      type: Object,
    },
    paymentTerm: {
      default: () => {},
      type: Object
    }
  },

  data() {
    return {
      errs: null,
      processing: false
    }
  },

  computed: {
    buttonText() {
      return this.$t('paymentTerms.pay_amount', { amount: this.paymentTerm.amount_decorated })
    }
  },

  methods: {
    ...mapMutations('checkout', ['updateStripeFormLoading']),

    close() {
      this.$emit('close')
    },

    confirmPaymentIntent(id) {
      let body = JSON.stringify({
        payment_intent: { payment_term_id: this.paymentTerm.id }
      })

      return FetchCall.fetchWithLocale(`/oa/payment_intents/${id}/confirm.json`, 'POST', body).
        then(paymentIntent => {
          if (paymentIntent.status == 'succeeded') {
            this.$emit('refresh')
          } else {
            this.processing = false
            this.errs = this.$t('paymentTerms.error_saving')
          }
        })
    },

    createPaymentIntent(id) {
      let body = JSON.stringify({
        payment_intent: {
          payment_term_id: this.paymentTerm.id,
          source_id: id
        }
      })

      return FetchCall.fetchWithLocale('/oa/payment_intents.json', 'POST', body).
        then(paymentIntent => { return paymentIntent })
    },

    paymentIntentSCA(intent, stripe) {
      stripe.paymentIntentSCA(intent.client_secret).then(result => {
        if (result.error) {
          this.processing = false
          this.errs = result.error.message
        } else {
          this.confirmPaymentIntent(result.paymentIntent.id)
        }
      })
    },

    payNow(cardData) {
      if (this.processing) { return}

      this.processing = true
      if (cardData.id) {
        this.createPaymentIntent(cardData.id).then(response => {
          this.updateStripeFormLoading(false)
          if (response.status == 'succeeded') {
            this.$emit('refresh')
          } else if (response.status == 'requires_action') {
            this.paymentIntentSCA(response, cardData.stripe)
          } else {
            this.processing = false
            this.errs = this.$t('paymentTerms.error_saving')
          }
        })
      }
    }
  }
}
</script>
