$(document)
  .on('click', '#to_form', function() {
    //check if sidebar or form is intended target
    if($('#sidebar').offset()){  //if sidebar
      $('html, body').animate({
        scrollTop: $('#sidebar').offset().top
      }, 800)
      $('#info_request_first_name').focus()
    }
    else{  //else form
      const id = document.getElementById('info_request_first_name')
      const element = document.getElementsByClassName('scroll-transition scrolled')[0]
      let yOffset = 0
      //if navbar is displayed, yOffset = navbar height, else yOffset = 0
      if(element) { yOffset = -element.offsetHeight }
      // scroll to top of form
      const y = id.getBoundingClientRect().top + window.pageYOffset + yOffset
      window.scrollTo({top: y, behavior: 'smooth'})

      //show focus after scrollTo function finishes
      setTimeout( function() { $('#info_request_first_name').focus()}, 800 )
    }
  })

  .on('turbo:load', () => {
    if ($('.package-paths').data('hide-pricing')) {
      $('.package-paths .pricing').css('display', 'none')
    }
  })

$(document)
if (window.name === 'ausopen') {
  $('.ticket-table-head').on('click', function() {
    const caret = $(this).find('.custom-caret')
    if (caret.length) {
      caret.toggleClass('rotate')
    }
  })
}
  
