<template>
  <div class="clipboard-codes">
    <div
      v-for="code in codes"
      :key="code"
      class="clipboard-code"
      @click="setUpClipboard(code)"
    >
      <p class="clipboard-text">
        {{ code }}
      </p>

      <p class="clipboard-click-text">
        {{ copyText }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    codes: {
      default: () => [],
      type: Array
    }
  },

  data() {
    return {
      copyText: ''
    }
  },

  created() {
    this.copyText = this.$t('f1TvCodes.click_to_copy')
  },

  methods: {
    copyTvCodeToClipboard(code) {
      navigator.clipboard.writeText(code).then(() => {
        this.copyText = this.$t('f1TvCodes.copied_code', { 'code': code })
      }).catch(() => {
        this.copyText = this.$t('f1TvCodes.copy_failed')
      })
    },

    setUpClipboard(code) {
      navigator.permissions.query({name: 'clipboard-write'}).then(permissionStatus => {
        // permissionStatus will be 'granted', 'denied' or 'prompt':
        if (permissionStatus.state == 'granted') {
          this.copyTvCodeToClipboard(code)
        } else {
          this.copyText = this.$t('f1TvCodes.copy_failed_permissions')
        }

        // Listen for changes to the permission state
        permissionStatus.onchange = () => { this.copyText = this.$t('f1TvCodes.click_to_copy') }
      })
    }
  }
}
</script>
