const parentMenu = '.navbar-second'
const menu = '.secondnav-dropdown'
const submenu = '.secondnav-submenu'

function scrollingNav() {
  // FIXME: what if margin-bottom is not specified in pixels?
  const navbarBottom = $('.navbar').height() + parseInt($('.navbar').css('marginBottom'))

  //$('.mobile-burger-nav').hide()
  $('.fader').show()

  if (navbarBottom) {
    return $(document).scroll(function() {
      let scrollTop = $(this).scrollTop()
      if (scrollTop > navbarBottom) { minimizeNav() } else { maximizeNav() }
      $(parentMenu).addClass('hidden')
      $(menu).addClass('hidden')
      $(submenu).addClass('hidden')
    })
  }
}

function minimizeNav() {
  $('#summaryContainer').removeClass('top-unscrolled')
  $('#summaryContainer').addClass('top-scrolled')
  $('.navbar').css('min-height','50px').addClass('scrolled')
  $('.anchor').addClass('scrolled-anchor')
  $('#navbar-bottom').addClass('scrolled')
  $('.bannerContainer').addClass('hidden')
  $('.mobile-burger-nav').show()
  $('.fader').addClass('scrolled')
  $('.cartLink').show()
  $('.phoneMessage').removeClass('hidden')
  $('.navbar-top').addClass('scrolled')
  $('.brand-logo-small').removeClass('hidden')
  $('.navbar-second').addClass('hidden')
}

function maximizeNav() {
  $('#summaryContainer').removeClass('top-scrolled')
  $('#summaryContainer').addClass('top-unscrolled')
  $('.navbar').removeClass('scrolled')
  $('#navbar-filter').addClass('scrolled')
  $('.anchor').removeClass('scrolled-anchor')
  if($('.f1-top-bar')){
    $('.f1-top-bar').slideDown(80)
  }
  $('#navbar-bottom').removeClass('scrolled')
  $('.bannerContainer').removeClass('hidden')
  $('.mobile-burger-nav').hide()
  $('.fader').removeClass('scrolled')
  $('.cartLink').hide()
  $('.secondnav-dropdown, .secondnav-submenu').addClass('hidden')
  $('.phoneMessage').addClass('hidden')
  $('.navbar-top').removeClass('scrolled')
  $('.brand-logo-small').addClass('hidden')
}

function extendHeader() {
  let customerButton = $('.login-as-customer')
  if (customerButton.length > 0) {
    $('header').css('padding-bottom', customerButton.outerHeight())
  }
}

$(document)

  .on('turbo:load', function() {
    scrollingNav()
    extendHeader()
    $('.occupancy_popover').popover({
      html: true,
      trigger: 'click',
      placement: 'right',
      title: 'Double Occupancy',
      container: 'body'
    })
  })
  .on('click', 'body', () =>
    $('.secondnav-dropdown, .secondnav-submenu, .navbar-second').addClass('hidden')
  )
  .on('click', '.desktop-burger-nav', function() {

    $(parentMenu).toggleClass('hidden')

    if ($(parentMenu).hasClass('open')) {
      $(menu).removeClass('hidden')
    } else if (!$(parentMenu).hasClass('open')) {
      $(menu).addClass('hidden')
    }

    if (!$(menu).hasClass('hidden')) {
      $(submenu).addClass('hidden')
    } else {
      $(menu).removeClass('hidden')
    }

    return false
  })
  .on('click', '.show-sub-menu', function() {
    event.preventDefault()
    $('.secondnav-dropdown').addClass('hidden')
    $($(this).data('target')).removeClass('hidden')

    return false
  })
  .on('click', '.back-to-main', function(event) {
    event.preventDefault()
    $(this).closest('ul').addClass('hidden')
    $('.secondnav-dropdown').removeClass('hidden')

    return false
  })
