/* global Turbo */
import '@selectize/selectize/dist/js/selectize'

$.autocomplete = require('jquery-ui/ui/widgets/autocomplete')

function search_term(term) {
  return encodeURI(`/api/events?q[translations_display_name_or_search_terms_cont]=${term}`)
}

function filterData(txtValue, element, filterTerm) {
  if (filterTerm.length && txtValue.toUpperCase() == filterTerm.toUpperCase()) {
    element.show()
  } else {
    element.hide()
  }
}
function filterProductData(txtValue, element, filterTerm) {
  if (filterTerm.length && txtValue.toUpperCase().indexOf(filterTerm) > -1) {
    element.show()
  } else {
    element.hide()
  }
}



let useAutocomplete = false

$(document).on('turbo:load', () =>
  $('#product_search').selectize({
  })
)

$(document).on('turbo:load', () =>
  $('#hotel_search').selectize({})
)

$(document).on('turbo:load', () =>
  $('#transportation_search').selectize({})
)

$(document).on('turbo:load', () =>
  $('#event_search input[type="search"]').autocomplete({
    select(event, ui) {
      $('.ui-menu').addClass('visible')
      $('.ui-menu').removeClass('hidden')
      event.preventDefault()
      if ((typeof Turbo !== 'undefined') && Turbo.supported) {
        Turbo.visit(ui.item.value)
      } else {
        window.location.href = ui.item.value
      }
      $('#event_search input[type="search"]').val(ui.item.label)
    },
    focus(event, ui) {
      $('.ui-menu').addClass('visible')
      $('.ui-menu').removeClass('hidden')
      event.preventDefault()
      $('#event_search input[type="search"]').val(ui.item.label)
    },
    source(request, response) {
      if (!useAutocomplete) {
        $('#search_field').removeClass('ui-autocomplete-loading')
        return []
      }
      $('.ui-menu').addClass('visible')
      $('.ui-menu').removeClass('hidden')
      return $.get(search_term(request.term), function(data) {
        let items = []
        $.each(data, function(key, item) {
          let label = item.display_name
          let value = item.renderable_path
          if (item.visible) { return items.push({label, value}) }
        })
        return response(items)
      })
    },
    appendTo: '.event_search'
  })
)

$(document)
  .on('click', '#filter_events', function(){
    $('.ui-menu').addClass('hidden')
    $('.ui-menu').removeClass('visible')
    let term = $('#event_search input[type="search"]').val()
    $.get(search_term(term), function(data) {
      let ids = []
      $.each(data, function(key, item) {
        ids.push(item.id)
      })

      $('.event-item').each(function(){
        let eventId = parseInt($(this).attr('data-id'))
        if (ids.includes(eventId)) {
          $(this).addClass('visible')
          $(this).removeClass('hidden')
        } else {
          $(this).addClass('hidden')
          $(this).removeClass('visible')
        }
      })

      if (ids.length == 0) {
        $('.no-results').removeClass('hidden')
        $('.cof-title').addClass('hidden')
      } else {
        $('.no-results').addClass('hidden')
        $('.cof-title').removeClass('hidden')
      }
    })
    $('.event-item:visible').filter(':even').addClass('even-results-row')
    $('.event-item:visible').filter(':odd').addClass('odd-results-row')
  })

  .on('keyup', '#search_field', function(btn){
    useAutocomplete = true
    btn.preventDefault()
    // 13 is the key code for the Enter keyboard btn
    if (btn.which === 13) {
      useAutocomplete = false
      $('#filter_events').click()
    }
  })

  .on('change', '#hotel_search', function(){
    var filterTerm = $(this).val().toUpperCase()

    if($('.hotel-content-wrapper').length > 0) {
      $('.display-hotel').each(function() {
        var hotel, txtValue, hotelName
        hotel = $(this)
        hotelName = hotel.find('.content h5').first()

        if(hotelName){
          txtValue = hotelName.text()
          filterData(txtValue, hotel, filterTerm)
        }
      })
    } else {
      $('.display-hotel').each(function(){
        var hotel, txtValue, hotelName
        hotel = $(this)
        hotelName = hotel.children('h5')

        if(hotelName){
          txtValue = hotelName.text()
          filterData(txtValue, hotel, filterTerm)
        }
      })
    }
  })

  .on('change', '#transportation_search', function(){
    var filterTerm = $(this).val().toUpperCase()
    $('.display-transportation').each(function(){
      var hotel, txtValue, hotelName
      hotel = $(this)
      hotelName = hotel.children('h5')
      if(hotelName){
        txtValue = hotelName.text()
        filterData(txtValue, hotel, filterTerm)
      }
    })
  })

  .on('change', '#product_search', function(){
    var filterTerm = $(this).val().toUpperCase()
    if(filterTerm.length) {
      if($('#productTable .product-details-wrapper').length > 0) {
        $('#productTable').css({'display': 'flex'})
        $('#productTable .product-details-wrapper').each(function(){
          var txtValue, product, span
          product = $(this)
          product.show()
          span = product.find('span:eq(0)')
          if(span.length){
            txtValue = span.text()
            filterProductData(txtValue, product, filterTerm)
          }
        })
      } else {
        $('#productTable').show()
        $('#productTable tr').each(function(){
          var txtValue, row, td
          row = $(this)
          row.show()
          td = row.find('td:eq(0)')
          if(td.length){
            txtValue = td.text()
            filterProductData(txtValue, row, filterTerm)
          }
        })
      }
    }
    else{
      $('#productTable').hide()
    }
  })
