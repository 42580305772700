<template>
  <p class="payment-term-count">
    <span>
      {{ $t('paymentTerms.payment_number', { number: index + 1 }) + ' ' }}
    </span>
    <span>
      {{ term.amount_decorated }} {{ currency }}
    </span>
  </p>
</template>

<script>
export default {
  name: 'PaymentTermsHeader',
  props: {
    currency: {
      default: 'USD',
      type: String
    },

    index: {
      default: null,
      required: true,
      type: Number
    },

    term: {
      default: () => {},
      type: Object,
    }
  }
}
</script>
