<!--eslint-disable vue/no-mutating-props -->
<template>
  <div>
    <div
      v-if="nhl_info_request.isNhlForm"
      class="nhl-info-request-consent"
    >
      <div
        v-if="nhl_info_request.isInCanada"
        class="form-group boolean"
      >
        <div
          class="checkbox"
        >
          <label class="small-label">
            <input
              id="nhl_member_club_info_sharing"
              v-model="nhl_info_request.nhl_member_club_opt_in"
              class="boolean"
              type="checkbox"
              name="marketing_email_consent"
            >
            <span class="custom-checkbox" />
            <span>
              Yes, share my information with the NHL and its <a
                href="https://www.nhl.com/info/nhl-affiliates-member-clubs"
                target="_blank"
              >Member Clubs</a> so they may send me news and offers about the NHL and its <a
                href="https://www.nhl.com/info/nhl-affiliates-member-clubs"
                target="_blank"
              >Member Clubs</a>.
            </span>
          </label>
        </div>
        <div
          class="checkbox"
        >
          <label class="small-label">
            <!-- id="info_request_marketing_email_consent" -->
            <input
              id="nhl_business_partner_info_sharing"
              v-model="nhl_info_request.nhl_business_partner_opt_in"
              class="boolean"
              type="checkbox"
              name="marketing_email_consent"
            >
            <span class="custom-checkbox" />
            <span>
              Yes, share my information with the NHL so the NHL may send me news and offers that may be of interest to me on behalf of its <a
                href="https://www.nhl.com/info/nhl-affiliates-member-clubs"
                target="_blank"
              >Business Partners</a> and share my information with its <a
                href="https://www.nhl.com/info/nhl-affiliates-member-clubs"
                target="_blank"
              >Business Partners</a> so they may send me similar messages.
            </span>
          </label>
        </div>
        <div class="checkbox">
          <label class="content-label">
            QuintEvents is seeking your consent on behalf of the NHL and the NHL’s <a
              href="https://www.nhl.com/info/nhl-affiliates-member-clubs"
              target="_blank"
            >Member Clubs</a>, and <a
              href="https://www.nhl.com/info/nhl-affiliates-member-clubs"
              target="_blank"
            >Business Partners</a>. You may withdraw your consent at any time.
          </label>
        </div>

        <div class="checkbox">
          <label class="content-label">
            QuintEvents, 9300 Harris Corners Parkway Suite 120, Charlotte, NC 28269  |  <a
              href="https://quintevents.com/get-in-touch"
              target="_blank"
            >Contact Us</a>  |  <a
              href="https://quintevents.com/privacy-policy"
              target="_blank"
            >QuintEvents Privacy Policy</a>
          </label>
        </div>
        <!-- eslint-disable vue/no-v-html -->
        <div class="checkbox">
          <label class="content-label">
            NHL, 1185 Avenue of the Americas, New York, NY 10036  |  <a
              href="https://www.nhl.com/info/contact-us"
              target="_blank"
            >NHL.com/contact</a>   | 
            <span v-html="$t('form.nhl_privacy_policy')" />
          </label>
        </div>
      </div>
      <div
        v-else
        class="form-group boolean"
      >
        <div
          class="checkbox"
        >
          <label class="small-label">
            <input
              id="nhl_member_club_info_sharing"
              v-model="nhl_info_request.nhl_member_club_opt_in"
              class="boolean"
              type="checkbox"
              name="marketing_email_consent"
            >
            <span class="custom-checkbox" />
            <span>
              Yes, I want to receive tailored news and offers from NHL and its <a
                href="https://www.nhl.com/info/nhl-affiliates-member-clubs#member"
                target="_blank"
              >Member Clubs.</a>
            </span>
          </label>
        </div>
        <div
          class="checkbox"
        >
          <label class="small-label">
            <!-- id="info_request_marketing_email_consent" -->
            <input
              id="nhl_business_partner_info_sharing"
              v-model="nhl_info_request.nhl_business_partner_opt_in"
              class="boolean"
              type="checkbox"
              name="marketing_email_consent"
            >
            <span class="custom-checkbox" />
            <span>

              Yes I want to receive news tailored news and offers from NHL's <a
                href="https://www.nhl.com/info/nhl-affiliates-member-clubs#business"
                target="_blank"
              >Business Partners.</a>
            </span>
          </label>
        </div>
        <div
          class="checkbox"
        >
          <label class="small-label">
            <input
              id="partner_marketing_consent"
              v-model="nhl_info_request.partner_marketing_consent"
              class="boolean"
              type="checkbox"
              name="partner_marketing_consent"
            >
            <span>
              Yes, I want to receive news and offers from the National Hockey League Players' Association.
              <a
                target="_blank"
                href="https://nhlpa.com/"
              >https://nhlpa.com/</a> | <a
                target="_blank"
                href="https://nhlpa.com/the-pa/what-we-do/contact-us"
              >https://nhlpa.com/the-pa/what-we-do/contact-us</a>
            </span>
          </label>
        </div>
        <div class="checkbox">
          <label
            class="content-label"
            v-html="$t('form.nhl_privacy_policy')"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {


  props:{
    // eslint-disable-next-line vue/prop-name-casing
    nhl_info_request: {
      default: () => {},
      type: Object
    },
    // eslint-disable-next-line vue/prop-name-casing
    in_eu:{
      default: false,
      type: Boolean
    }
  },
}
</script>
