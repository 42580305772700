<template>
  <div class="opportunity-line-items details">
    <ul
      v-if="lineItems.length > 0"
      class="order-item-list details"
    >
      <li
        v-for="item in lineItems"
        :key="item.id"
        class="details details-block"
      >
        <div class="details">
          <label class="details">
            {{ $t('opportunity.item') }}
          </label>
          {{ item.name }}
        </div>

        <div class="details">
          <label class="details">
            {{ $t('opportunity.quantity') }}
          </label>
          {{ item.quantity }}
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import FetchCall from '../../../shared/local_fetch_call'

export default {
  data() {
    return {
      lineItems: [],
      opportunityId: null
    }
  },

  created() {
    let elem = document.getElementById('opportunityLineItems')
    if(elem) {
      this.opportunityId = elem.dataset.opportunityid
    }
    if (this.opportunityId) { this.fetchLineItems() }
  },

  methods: {
    fetchLineItems(){
      FetchCall.fetchWithoutLocale(`/oa/opportunities/${this.opportunityId}/opportunity_line_items.json`,
        'GET',
        null).
        then(response => {
          if (response.line_items) { this.lineItems = response.line_items }
        })
    },
  }
}
</script>
