<template>
  <div :class="[loading ? 'loading-card' : '', 'stripe-card-form']">
    <div class="loading-icon" />

    <div class="card-fields tile">
      <div class="card-field-group card-number field">
        <div
          id="card-number"
          class="input-field"
        />

        <div
          id="cardErr"
          class="card-errors is-danger help"
        />
      </div>

      <div class="card-field-group card-cvc field">
        <div
          id="card-cvc"
          class="input-field"
        />

        <div
          id="cvcErr"
          class="card-errors is-danger help"
        />
      </div>

      <div class="card-field-group card-exp field">
        <div
          id="card-exp"
          class="input-field"
        />

        <div
          id="cardExpErr"
          class="card-errors is-danger help"
        />
      </div>

      <div
        id="card-errors"
        class="card-errors is-danger help"
      />
    </div>

    <div
      v-if="(includechecks == 'true' && !loading)"
      class="card-check-boxes level field"
    >
      <input
        id="terms"
        v-model="acceptedTerms"
        type="checkbox"
        value="true"
        @change="updateTerms"
      >
      <span class="custom-checkbox" />
      <div class="print-link">
        <!-- eslint-disable vue/no-v-html -->
        <label
          for="terms"
          v-html="$t('creditCardForm.acceptedTerms')"
        />
        <!--eslint-enable-->
        <span class="print-btn fa fa-print" />

        <div :class="displayErrorClass">
          {{ displayError }}
        </div>
      </div>
      <PrivacyCheckbox
        :nba="false"
        :checkout="true"
        :error="placeOrderError"
        @errorresolved="errorResolved"
        @privacyconsent="updatePrivacy"
      />
      <input
        id="f1consent"
        v-model="f1Consent"
        type="checkbox"
        class="f1consent"
        value="true"
        @change="$emit('update-f1', f1Consent)"
      >

      <label
        class="f1consent"
        for="f1consent"
      >
        {{ $t('creditCardForm.f1Consent') }}
      </label>
    </div>
    <button
      :disabled="!valid"
      class="continue-button btn btn-primary"
      @click="generateCardId"
    >
      {{ buttontext }}
    </button>
  </div>
</template>

<script>
import CartApi from '../../shared/cart/cart_api'
import { mapMutations } from 'vuex'
import StripeJS from './stripe.js'
import PrivacyCheckbox from '../../default/privacy_checkbox'

export default {
  components: {
    PrivacyCheckbox
  },
  props: {
    address: {
      default: null,
      required: true,
      type: String,
    },

    address2: {
      default: null,
      required: false,
      type: String,
    },

    buttontext: {
      default: null,
      required: true,
      type: String,
    },

    city: {
      default: null,
      required: true,
      type: String,
    },

    includechecks: {
      default: 'false',
      required: true,
      type: String,
    },

    name: {
      default: null,
      required: true,
      type: String,
    },
    phone: {
      default: null,
      required: false,
      type: String,
    },

    valid: {
      default: true,
      type: Boolean
    },

    state: {
      default: null,
      required: false,
      type: String,
    },

    zipcode: {
      default: '',
      required: true,
      type: String,
    },
    errs: {
      default: null,
      type: String,
      required: false
    }
  },

  data() {
    return {
      amexPromotion: false,
      acceptedTerms: false,
      displayError: null,
      f1Consent: false,
      privacyConsent: false,
      placeOrderError: false
      // loading: false,
    }
  },

  computed: {
    loading(){
      return this.$store.state.checkout.stripeFormLoading
    },
    order(){
      return this.$store.state.order.order
    },
    eu(){
      return this.$store.state.order.order.in_eu
    },
    billingDetails() {
      return {
        address: {
          city: this.city || '',
          line1: this.address || '',
          line2: this.address2 || '',
          postal_code: this.zipcode || '',
          state: this.state || ''
        },
        name: this.name || '',
        phone: this.phone || ''
      }
    },
    displayErrorClass() {
      if (this.displayError == this.displayErrorText) {
        return 'card-errors is-danger help'
      } else {
        return 'hidden'
      }
    },

    displayErrorText() {
      return this.$t('creditCardForm.pleaseAccept')
    },

    disableSubmit() {
      if (this.includechecks == 'true' && this.acceptedTerms == false) {
        return true
      } else {
        return false
      }
    }
  },

  watch: {
    errs(){
      this.hasErrors()
    }
  },

  beforeDestroy() {
    StripeJS.removeElements()
  },

  mounted(){

    this.buildStripeForm()
  },

  methods: {
    ...mapMutations('checkout', ['updateStripeFormLoading']),
    generateCardId() {
      // Don't submit button twice
      if (this.loading) { return }
      // Don't process if acceptedTermsAndConditions or privacyPolicy(in_eu) are false
      if (this.disableSubmit && (this.includechecks == 'true' && this.eu && !this.privacyConsent)) {
        this.displayError = this.displayErrorText
        this.placeOrderError = true
        return
      } else if (this.amexPromotionFails()){
        return
      } else if (this.disableSubmit){
        this.displayError = this.displayErrorText
        return
      } else if (this.includechecks == 'true' && this.eu && !this.privacyConsent) {
        this.placeOrderError = true
        return
      }

      this.updateStripeFormLoading(true)
      StripeJS.cardIDToBeChargedImmediately(this.billingDetails).then(cardId => {
        if (cardId === 'error') {
          // Stripe will generate errors here
          this.updateStripeFormLoading(false)
        } else {
          this.$emit('card-added', { id: cardId, stripe: StripeJS })
        }
      })
    },

    amexPromotionFails() {
      if (this.amexPromotion) {
        return document.getElementById('amex-error') && document.getElementById('amex-error').innerText != ''
      } else {
        return false
      }
    },

    buildStripeForm(){
      CartApi.getDiscounts().then((discount) => {
        if(discount && discount.discount_type == 'amex_only'){
          this.amexPromotion = true
          StripeJS.generateForm(this.amexPromotion)
        }
        else {
          StripeJS.generateForm()
        }
      })

    },

    errorResolved(){
      this.placeOrderError = false
    },

    updatePrivacy(bool){
      this.privacyConsent = bool
    },
    updateTerms() {
      if (this.acceptedTerms == false) {
        this.displayError = this.displayErrorText
      } else {
        this.displayError = ''
      }

      this.$emit('update-terms', this.acceptedTerms)
    },

    hasErrors() {
      if(this.errs != null || this.errs != '') {
        this.updateStripeFormLoading(false)
      }
    }
  },
}
</script>
