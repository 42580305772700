let openPaymentTerms = () => {
  let paymentTermsDiv = document.getElementsByClassName('opportunity-payment-terms')[0]
  paymentTermsDiv.querySelector('.opportunity-details-header').classList.remove('closed')
  paymentTermsDiv.querySelector('.opportunity-details-header').classList.add('opened')
  paymentTermsDiv.querySelector('.opportunity-details-content').classList.remove('closed')
  paymentTermsDiv.querySelector('.opportunity-details-content').classList.add('opened')
}

export default {
  openPayment() {
    let div = document.querySelectorAll('.opportunity-details-block.expand-block')

    let header, content = null
    if (div[0]){
      content = div[0].querySelector('.opportunity-details-content')
      header = div[0].querySelector('.opportunity-details-header')
    }

    if (header && content) {
      openPaymentTerms()
    }
  }
}
