export function loadBlogElements() {
  let cubeWrapper = document.querySelector('.blog-cube-wrapper')
  if(cubeWrapper) {
    let blogs = document.querySelectorAll('.blog-cube-wrapper .blog-cube')
    const totalBlogs = blogs.length
    const blogsPerClick = 16
    let displayedBlogs = blogsPerClick
    let expanding = true
    let toggleBtn = document.querySelector('.load-more.toggle-btn')
    const arrowElem = toggleBtn.querySelector('.arrow-wrapper')

    //Initial load
    for (let i = 0; i < blogsPerClick  && i < blogs.length; i++) {
      blogs[i].classList.remove('collapsed')
    }

    if (totalBlogs <= blogsPerClick) {
      toggleBtn.classList.add('hide')
    }

    toggleBtn.addEventListener('click', () => {
      if (expanding) {
        // expanding the blogs
        toggleBlogVisibility(blogs, displayedBlogs + 1, displayedBlogs + blogsPerClick, true)
        displayedBlogs += blogsPerClick
        if (displayedBlogs >= totalBlogs) {
          expanding = false
          toggleBtn.innerText = 'Show Less'

          if(arrowElem) {
            toggleBtn.appendChild(arrowElem)
            arrowElem.classList.add('flipped')
          }
        }
        scrollToggleBtnTop(toggleBtn)

      } else {
        if (displayedBlogs > blogsPerClick) {
          // collapsing the blogs
          toggleBlogVisibility(blogs, blogsPerClick + 1, totalBlogs, false)
          displayedBlogs = blogsPerClick
          expanding = true
          toggleBtn.textContent = 'Load More'

          if(arrowElem) {
            toggleBtn.appendChild(arrowElem)
            arrowElem.classList.remove('flipped')
          }

          scrollHeaderTop()
        }
      }
    })
  }
}

function toggleBlogVisibility(blogs, startIndex, endIndex, isVisible) {
  for (let i = startIndex - 1; i < endIndex  && i < blogs.length; i++) {
    if (isVisible) {
      blogs[i].classList.remove('collapsed')
    } else {
      blogs[i].classList.add('collapsed')
    }
  }
}

function scrollHeaderTop() {
  let scrollHeader = document.querySelector('.scroll-header')

  if(scrollHeader) {
    let scrollHeaderTop = scrollHeader.getBoundingClientRect().top - 75 //navHeight
    setTimeout(() => {
      window.scrollBy({
        top: scrollHeaderTop,
        behavior: 'smooth'
      })
    }, 400)
  }
}

function scrollToggleBtnTop(toggleBtn) {
  setTimeout(() => {
    window.scrollBy({
      top: toggleBtn.getBoundingClientRect().top + 20,
      behavior: 'smooth'
    })
  }, 400)
}