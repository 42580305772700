let updateClasses = (header, content) => {
  if (header.classList.contains('closed')) {
    header.classList.remove('closed')
    content.classList.remove('closed')

    header.classList.add('opened')
    content.classList.add('opened')
  } else {
    header.classList.remove('opened')
    content.classList.remove('opened')

    header.classList.add('closed')
    content.classList.add('closed')
  }
}

export default {
  addOpenCloseClasses() {
    Array.from(document.querySelectorAll('.opportunity-details-block.expand-block')).forEach(div => {
      let header = div.querySelector('.opportunity-details-header')
      let content = div.querySelector('.opportunity-details-content')

      if (header && content) {
        header.addEventListener('click', () => { updateClasses(header, content) })
      }
    })
  }
}
