<template>
  <div class="rooming-list-form">
    <p>
      <strong>{{ $t('roomingLists.roomNum', { number: num }) }}</strong>
      {{ room.display_name }}
    </p>

    <p>{{ room.room_type_translated }}</p>

    <div
      v-if="completedGuestName == null"
      class="hotel-reservation-info-group"
    >
      <label for="room_guest_name">
        {{ $t('roomingLists.resName') }}
      </label>

      <input
        v-model="guestName"
        class="form-control"
        name="room_guest_name"
        type="text"
        @keyup="resetMessage"
      >
    </div>
    <div
      v-else
      class="hotel-reservation-info-group"
    >
      <label for="room_guest_name">
        {{ $t('roomingLists.resName') }}
      </label>

      <p>{{ guestName }}</p>
    </div>

    <div
      v-if="completedNumberOfAdults > 0"
      class="hotel-reservation-info-group"
    >
      <label for="max_room_capacity">
        {{ $t('roomingLists.numAdults') }}
      </label>
      <p>{{ numberOfAdults }}</p>
    </div>
    <div
      v-else
      class="hotel-reservation-info-group"
    >
      <label for="max_room_capacity">
        {{ $t('roomingLists.numAdults') }}
      </label>

      <select
        id="max_room_capacity"
        v-model="numberOfAdults"
        class="form-control"
      >
        <option
          v-for="n in room.max_room_capacity"
          :key="n"
          :value="n"
          @change="resetMessage"
        >
          {{ n }}
        </option>
      </select>
    </div>

    <button
      v-if="incomplete"
      class="btn btn-primary update-rooming-list-button"
      @click="updateRoom"
    >
      {{ $t('roomingLists.save') }}
    </button>

    <p
      v-if="message != null"
      @click="resetMessage"
    >
      {{ message }}
    </p>
  </div>
</template>

<script>
import FetchCall from '../../../shared/local_fetch_call'

export default {
  props: {
    num: {
      default: null,
      required: true,
      type: Number
    },

    opportunityid: {
      default: null,
      type: String,
      required: true
    },

    room: {
      default: () => {},
      required: true,
      type: Object
    }
  },

  data() {
    return {
      completedGuestName: null,
      completedNumberOfAdults: 0,
      guestName: null,
      message: null,
      numberOfAdults: 2,
      processing: false
    }
  },

  computed: {
    incomplete() {
      return this.completedGuestName == null || this.completedNumberOfAdults == 0
    }
  },

  created() {
    this.completedGuestName = this.room.guest_name
    this.guestName = this.room.guest_name

    if (this.room.quantity > 0) {
      this.completedNumberOfAdults = this.room.quantity
      this.numberOfAdults = this.room.quantity
    }
  },

  methods: {
    emitCompletedRoom() {
      if (this.incomplete) { return }
      this.$emit('complete-room', this.room.id)
    },

    resetMessage() {
      this.message = null
    },

    updateRoom() {
      if (this.processing) { return }

      this.processing = true

      let body = JSON.stringify({
        rooming_list: { guest_name: this.guestName, quantity: this.numberOfAdults }
      })

      FetchCall.fetchWithLocale(
        `/oa/opportunities/${this.opportunityid}/rooming_lists/${this.room.id}`, 'PUT', body
      ).then(response => {
        if (response.errors && Object.keys(response.errors).length == 0) {
          this.completedNumberOfAdults = this.numberOfAdults
          this.completedGuestName = this.guestName
          this.message = null
          this.emitCompletedRoom()
        } else {
          this.message = this.$t('roomingLists.updateFailed')
        }

        this.processing = false
      })
    }
  }
}
</script>
