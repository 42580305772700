<template>
  <div class="reservation-card-container">
    <div class="reservation-card-image-container">
      <div class="hotel-image">
        <img :src="getImage()">
      </div>
    </div>

    <div class="reservation-details">
      <div class="hotel-details">
        <h5 class="hotel-name">
          {{ reservation.hotel_name }}
        </h5>
        <p class="hotel-address">
          {{ reservation.hotel_json_info.address }}
        </p>
      </div>

      <hr>

      <div class="reservation-info">
        <div class="check-in-check-out">
          <div class="check-in">
            <h5>Check In</h5>
            {{ reservation.check_in_date }}
          </div>
          <div class="check-out">
            <h5>Check Out</h5>
            {{ reservation.check_out_date }}
          </div>
        </div>

        <div class="confirmation-number">
          <h5>Confirmation Number</h5>
          {{ reservation.confirmation_number }}
        </div>
      </div>

      <hr>

      <div class="buttons">
        <a :href="reservationLink">
          <div
            class="btn btn-primary view-reservation"
          >
            View Reservation
          </div>
        </a>
        <div
          class="btn btn-secondary modify-reservation first"
          @click="modifyReservation('modification')"
        >
          Modify Reservation
        </div>
        <div
          v-if="reservation.cancellable"
          class="btn btn-secondary modify-reservation"
          @click="modifyReservation('cancellation')"
        >
          Cancel Reservation
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import FetchCall from '../../shared/local_fetch_call'
export default {
  props: {
    reservation: {
      default: () => {},
      required: true,
      type: Object,
    },
  },

  data() {
    return {
      images: [],
      hotel: {}
    }
  },

  computed: {
    reservationLink() {
      return `/reservations/${this.reservation.id}`
    }
  },

  methods: {
    getImage() {
      return this.reservation.hotel_json_info.images[0].ImagePath
    },
    modifyReservation(type){
      let body = JSON.stringify({id: this.reservation.id, type})
      FetchCall.fetchWithLocale('/reservation_modifications', 'post', body).then(res=> {
        if(res.action =='cancellation'){
          window.location.href = '/reservation-cancellation'
        } else if (res.action == 'modification')
          window.location.href = '/reservation-modification'
      })
    }
  }
}
</script>
