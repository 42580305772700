import { t } from './translate_override.js'

$.validator.addMethod(
  'emailTest',
  value => {
    const pattern = /.+@.+\..+/i
    return pattern.test(value)
  },
  $.validator.messages.email
)

$.validator.addMethod(
  'roomQuantityTest',
  value => {
    const pkgQty = $('.new_order_item .quantity-select').find('option:selected').val() || 0
    const packagePrice = $('.new_order_item .package-select').find('option:selected').data('price') || 0
    return (value <= pkgQty) || (packagePrice <= 0)
  },
  t('rejectRoomQuantity')
)

const success = element => $(element).remove().prev().removeClass('formError')

const highlight = element => $(element).closest('.form-group').addClass('has-error')

const unhighlight = element => $(element).closest('.form-group').removeClass('has-error')

const newsletter_validations = {
  rules: {
    'newsletter[email]': {
      required: true,
      emailTest: true
    },
    'newsletter[first_name]': 'required',
    'newsletter[last_name]': 'required'
  },
  onfocusout: function(element) { this.element(element) },
  success,
  highlight,
  unhighlight
}


$(document)
  .on('turbo:load', function() {
    $('.simple_form.newsletter').validate(newsletter_validations)
    $.extend($.validator.messages, {
      required: t('required'),
      rejectRoomQuantity: t('rejectRoomQuantity'),
      rejectCountry: t('rejectCountry')
    })
  })
