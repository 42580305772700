<template>
  <!-- eslint-disable vue/no-v-html -->
  <div
    v-if="requireCookies"
    class="form-group boolean required info_request_marketing_email_consent privacy-checkbox"
  >
    <div class="checkbox">
      <label>
        <Field
          id="info_request_marketing_email_consent"
          v-model="acceptedCheckoutConsent"
          class="boolean required"
          type="checkbox"
          name="marketing_email_consent"
          value="literally any string works"
          :rules="marketingConsent"
          @change="updateTerms"
        />
        <span class="custom-checkbox" />

        <div>
          <span
            v-if="nba && nba.isNbaForm"
            v-html="'*' + $t('form.nba_marketing_email_consent')"
          />
          <span
            v-if="isNhl"
            v-html="$t('form.nhl_marketing_email_consent')"
          />
          <span
            v-else
            class="payment-agreement-label"
            v-html="$t('form.marketing_email_consent')"
          />
          <div
            v-if="error || displayCheckoutError"
            :class="displayErrorClass"
          >
            Please consent to the privacy policy
          </div>
          <ErrorMessage
            as="label"
            class="error"
            name="marketing_email_consent"
          />
        </div>
      </label>
    </div>
  </div>
</template>

<script>
import { Field, ErrorMessage, defineRule } from 'vee-validate'

defineRule('required', value => {
  if (!value || !value.length) {
    return 'This field is required'
  }
  return true
})

export default {
  name: 'PrivacyCheckbox',
  components: {
    Field,
    ErrorMessage
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['instructions', 'nba', 'checkout', 'error', 'us', 'isNhl'],
  data() {
    return {
      acceptedCheckoutConsent: false,
      displayError: null,

      // loading: false,
    }
  },

  computed: {
    order(){
      return  this.$store.state.order.order
    },
    requireCookies() {
      return !this.inUS || this.inCanada || this.eu
    },
    eu(){
      return this.order && this.order.in_eu
    },
    inCanada() {
      return this.order.in_canada
    },
    inUS() {
      return this.us
    },
    displayErrorClass() {
      return 'card-errors is-danger help'
    },
    displayCheckoutError(){
      return this.checkout && this.displayError
    }
  },
  watch: {
    acceptedCheckoutConsent() {
      this.$store.state.checkout.privacyConsent = this.acceptedCheckoutConsent ? true : false
    }
  },
  methods: {
    updateTerms(){
      if(this.checkout){
        if (this.acceptedCheckoutConsent) {
          this.$emit('errorresolved')
          this.displayError = false
        } else {
          this.displayError = true
        }

        this.$emit('privacyconsent', !this.displayError)
      }
    },
    marketingConsent: (value) => {
      if (value && value.length) {
        return true
      }
      return 'Please consent to the privacy policy'
    },
  }
}
</script>
