<template>
  <div
    class="reservations-history container"
  >
    <div class="reservations_history">
      <h2 class="heading">
        Your Reservations
      </h2>
      <!--Desktop-->
      <div class="hotel-search-container">
        <div class="bookings-cnt-text">
          Showing {{ tabActive }} bookings ({{ totalReservationsLength }} total)
        </div>
        <div class="search-reservations">
          <ReservationsSearch @searchResult="searchReservations" />
        </div>
      </div>
      <!--Mobile-->
      <div class="reservations-container">
        <div
          v-if="windowWidth < 768"
          id="reservations_accoridion"
          class="panel-group"
          role="tablist"
        >
          <div
            v-for="(tabName, index) in tabNames"
            :key="tabName"
            class="panel panel-default"
          >
            <div
              :id="'heading' + tabName"
              class="panel-heading"
              role="tab"
            >
              <p class="panel-heading-title">
                <a
                  :aria-controls="'collapse' + tabName"
                  data-toggle="collapse"
                  data-parent="#reservations_accoridion"
                  :href="'#' + tabName"
                  :class="tabName == 'upcoming' ? '': 'collapsed'"
                  @click="addTabActive(tabName)"
                >
                  <h4 class="panel-title">
                    <b class="tab-name">{{ tabName }}</b> ({{ getBookings(tabName) }})
                  </h4>
                </a>
              </p>
            </div>
            <div
              :id="tabName"
              class="panel-collapse collapse"
              :class="index == 0 ? 'in': ''"
            >
              <div class="panel-body">
                <div
                  v-if="totalReservations[index].count > 0"
                  class="reservations"
                >
                  <ReservationCard
                    v-for="reservation in totalReservations[index].reservations"
                    :key="reservation.id"
                    :reservation="reservation"
                  />
                </div>
                <div
                  v-else
                  class="no-reservations"
                >
                  No bookings found.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-else
          class="tabs-data"
        >
          <ul
            class="reservation-nav-tabs"
            role="tablist"
          >
            <li
              v-for="tabName in tabNames"
              :key="tabName"
              role="presentation"
              class="reservation-tab-headings"
              :class="tabName == 'upcoming' ? 'active' : ''"
              @click="addTabActive(tabName)"
            >
              <a
                :href="'#' + tabName"
                :aria-controls="tabName"
                role="tab"
                data-toggle="tab"
                class="tab-heading-name"
              >
                <b class="tab-name">{{ tabName }}</b> ({{ getBookings(tabName) }})
              </a>
            </li>
          </ul>

          <div class="tab-content">
            <div
              v-for="(tabName, index) in tabNames"
              :id="tabName"
              :key="tabName"
              role="tabpanel"
              class="tab-pane"
              :class="tabName == 'upcoming' ? 'active' : ''"
            >
              <div
                v-if="totalReservations[index].count > 0"
                class="reservations"
              >
                <ReservationCard
                  v-for="reservation in totalReservations[index].reservations"
                  :key="reservation.id"
                  :reservation="reservation"
                />
              </div>
              <div
                v-else
                class="no-reservations"
              >
                No bookings found.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import FetchCall from '../../shared/local_fetch_call'
import ReservationCard from './reservation_card'
import ReservationsSearch from './reservations_search'
import Hotel from './hotel'
import { mapMutations } from 'vuex'

export default {
  components: {
    ReservationCard,
    ReservationsSearch,
  },

  data() {
    return {
      pastReservations: [],
      upcomingReservations: [],
      cancelledReservations: [],
      totalReservations: [],
      pastReservationsLength: 0,
      upcomingReservationsLength: 0,
      cancelledReservationsLength: 0,
      totalReservationsLength: 0,
      tabActive: '',
      tabNames: ['upcoming', 'past', 'cancelled'],
      windowWidth: window.innerWidth,
      isActive: false,
    }
  },

  computed: {
    isReservationSelected() {
      return this.$store.state.reservation.reservation ? true : false
    },
  },

  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth
    }
  },
  created() {
    window.history.replaceState(null, null, 'reservations')
    this.fetchReservations()
  },

  methods: {
    ...mapMutations('reservation', ['setAllReservationsObj']),

    fetchReservations() {
      FetchCall.fetchWithoutLocale(
        '/api/hotel_reservations/history.json',
        'GET',
        null
      ).then((response) => {
        this.setAllReservationsObj(response)
        this.searchReservations(response)
      })
    },

    getReservationsLength(tabName) {
      if (tabName == 'upcoming') return this.upcomingReservationsLength
      else if (tabName == 'past') return this.pastReservationsLength
      else return this.cancelledReservationsLength
    },

    addTabActive(tabName) {
      this.tabActive = this.getReservationsLength(tabName) + ' ' + tabName
    },

    getBookings(tabName) {
      let number = this.getReservationsLength(tabName)
      return number + ' booking' + (number !== 1 ? 's' : '')
    },

    setHotelInfo(reservations) {
      reservations.forEach( (reservation) => {
        reservation['hotel_json_info'] = new Hotel(JSON.parse(reservation.hotel_info))
      })
      return reservations
    },

    searchReservations(response) {
      if (response.past_reservations) {
        this.pastReservations = this.setHotelInfo(response.past_reservations)
      }

      if (response.upcoming_reservations) {
        this.upcomingReservations = this.setHotelInfo(response.upcoming_reservations)
      }

      if (response.cancelled_reservations) {
        this.cancelledReservations = this.setHotelInfo(response.cancelled_reservations)
      }

      this.upcomingReservationsLength = this.upcomingReservations.length
      this.pastReservationsLength = this.pastReservations.length
      this.cancelledReservationsLength = this.cancelledReservations.length

      this.totalReservationsLength =
        this.pastReservationsLength +
        this.upcomingReservationsLength +
        this.cancelledReservationsLength

      this.totalReservations = [
        {
          reservations: this.upcomingReservations,
          name: 'upcoming',
          count: this.upcomingReservationsLength,
        },
        {
          reservations: this.pastReservations,
          name: 'past',
          count: this.pastReservationsLength,
        },
        {
          reservations: this.cancelledReservations,
          name: 'cancelled',
          count: this.cancelledReservationsLength,
        },
      ]

      this.tabActive = this.upcomingReservationsLength + ' upcoming'
    },

    showHidePanel() {
      this.isActive = !this.isActive
    }
  },
}
</script>
