<template>
  <div class="hotel-image-parent">
    <div class="hotel-image-container">
      <div class="glide image-carousel">
        <div
          class="glide__track"
          data-glide-el="track"
        >
          <div
            ref="carousel"
            class="glide-carousel"
          >
            <!-- carousel -->
            <img
              v-for="(image, index) in images"
              :key="index"
              class="image"
              :src="image.ImagePath"
              alt="image.ImageCaption"
            >
          </div>
        </div>
        <div
          class="glide__arrows"
          data-glide-el="controls"
        >
          <button
            class="glide__arrow glide__arrow--left previous"
            data-glide-dir="<"
          />
          <button
            class="glide__arrow glide__arrow--right next"
            data-glide-dir=">"
          />
        </div>
      </div>
    </div>
    <p
      v-show="retail"
      class="image-disclaimer"
    >
      <b>{{ $t('h4h.note') }}</b> {{ $t('h4h.imageDisclaimer') }}
    </p>
  </div>
</template>

<script>

import Glide from '@glidejs/glide'

export default {
  props: {
    images: {
      type: Array,
      default: null
    },
    retail: {
      type: Boolean,
      default: true
    }
  },

  data(){
    return {
      glider:null
    }
  },

  mounted(){
    let carousel = document.querySelector('.image-carousel')
    carousel.addEventListener('wheel', this.handleWheel)
    const options = {
      type: 'carousel',
      startAt: 0,
      perView: this.retail ? 5 : 3,
      perTouch: 3,
      breakpoints: {
        1500: { perView: this.retail ? 4 : 3 },
        1250: { perView: this.retail ? 4 : 2 },
        1025: { perView: this.retail ? 3 : 2 },
        700: { perView: this.retail ? 2 : 1 },
        450: { perView: 1 }
      },
    }
    this.glider = new Glide('.glide', options).mount()
  },

  methods: {
    handleWheel(){
      if(event.deltaX != 0 && event.deltaX > 0){
        // scrolling right
        event.preventDefault()
        this.glider.go(`=${this.nextImage()}`)
      }
      else if (event.deltaX != 0 && event.deltaX < 0){
        // scrolling left
        event.preventDefault()
        this.previousImage()
      }
    },
    nextImage() {
      if(this.glider['_i']===this.images.length-1){
        document.querySelector('.glide__arrow--right').click()
      } else {
        this.glider.go(`=${this.images.length - (this.images.length - this.glider['_i'] - 1)}`)
      }
    },
    previousImage() {
      if(this.glider['_i']===0){
        document.querySelector('.glide__arrow--left').click()
      } else {
        this.glider.go(`=${this.images.length - (this.images.length - this.glider['_i'] + 1)}`)
      }
    }
  }
}
</script>
