/* global locale */
import { createApp } from 'vue/dist/vue.esm-bundler'
import { createI18n } from 'vue-i18n'
import CookieConsent from '../shared/cookie_consent.vue'
import { messages } from 'default/translated_text'

function cookie_tracked() {
  return document.getElementsByClassName('cookie-consent-dropdown')[0] == null
}

function add_div() {
  var cookie_consent_div = document.createElement('div')
  cookie_consent_div.setAttribute('id', 'cookie-consent')
  var parentDiv = document.getElementsByTagName('header')[0]
  parentDiv.appendChild(cookie_consent_div)
}

function add_cookie_consent() {
  if (cookie_tracked()) { return }

  if (document.getElementById('cookie-consent') == null) {
    add_div()
  }

  add_cookie_component()
}

function add_cookie_component() {
  const i18n = createI18n({ locale, messages, fallbackLocale: 'en', warnHtmlInMessage: 'off' })
  const app = createApp({
    el: '#cookie-consent',
    components: { CookieConsent },
    template: '<CookieConsent/>'
  })
  app.use(i18n)
  app.mount(document.getElementById('cookie-consent'))
}

document.addEventListener('turbo:load', function(){
  add_cookie_consent()
}, true)
